import { APP } from './constants';

export const isProd = () => true;

export const CONFIG = {
  APP_NAME: APP.name,
  DISPLAY_NAME: APP.displayName,
  SOURCE: APP.source,
  APP_URL: isProd() ? APP.url : 'http://localhost:3000',
  API_URL: isProd() ? APP.api : 'http://localhost:4200',
  SOCKET_URL: isProd() ? APP.socket : 'http://localhost:4200',
  FILES_URL: isProd() ? APP.files : 'https://d3g533dw0kelxj.cloudfront.net/inyter-dev/',
  PAYMENT_KEY: isProd() ? APP.payment_key : 'rzp_test_hW9yF82maArfME',
  ANALYTICS_ID: APP.analytics_id,
  GOOGLE_CLIENT_ID: '655192435891-fhhq0t6tm5oe7utr8j88jcf4hc6g3pm3.apps.googleusercontent.com',
  LOGO_URL: APP.logo_url,
  BANNER_URL: APP.banner_url,
  TITLE: APP.title,
  DESCRIPTION: APP.description,
  KEYWORDS: APP.keywords,
  TIMEOUT: 180000
} as any;
