import { BarChart3, CalendarClock, Film, ImagePlus, MessagesSquare, Settings2, Share2, Tv2 } from 'lucide-react';
import FeaturesItem from './FeaturesItem';

const features = [
  {
    title: 'Customizable Templates',
    text: 'Users can choose from a variety of pre-designed templates tailored to different occasions like birthdays, weddings, graduations, etc.',
    icon: Film
  },
  {
    title: 'Personalization Options',
    text: 'Ability to add personalized text, images, and videos to the invitation or wish, such as names, dates, and specific messages.',
    icon: Settings2
  },
  {
    title: 'Multimedia Integration',
    text: 'Support for incorporating multimedia elements like music, sound effects, animations, and video clips to enhance the overall presentation.',
    icon: ImagePlus
  },
  {
    title: 'Social Media Sharing',
    text: 'Seamless sharing options to distribute the video invitation or wish via social media platforms, email, or messaging apps, maximizing its reach.',
    icon: Share2
  },
  {
    title: 'HD Quality Output',
    text: 'Ability to create and export videos in high-definition quality to ensure a professional and polished look.',
    icon: Tv2
  },
  {
    title: 'Scheduling Features',
    text: 'Option to schedule the delivery of video invitations or wishes for a specific date and time, allowing users to plan ahead.',
    icon: CalendarClock
  },
  {
    title: 'Analytics and Tracking',
    text: 'Analytics tools to track the engagement metrics of the video invitation or wish, including views, responses, and interactions.',
    icon: BarChart3
  },
  {
    title: 'Customer Support',
    text: 'Access to customer support services to assist users with any technical issues or questions they may encounter during the creation process.',
    icon: MessagesSquare
  }
];

const Features = () => {
  return (
    <section className="bg-background flex flex-col justify-center" id="features">
      <div className="container mx-auto px-3 sm:px-0">
        <div className="text-3xl text text-center mt-12 mb-5">Features</div>
        <div className="text text-base mb-12 text-center max-w-[900px] mx-auto">
          Creating video invitations and wishes can be a fun and engaging way to invite people to events or convey your sentiments for various
          occasions. Here are some features commonly found in platforms or tools for creating video invitations and wishes:
        </div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 grid-cols-1 gap-x-12 gap-y-16 mb-16">
          {features.map(f => (
            <FeaturesItem key={f.title} title={f.title} text={f.text} icon={f.icon} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
