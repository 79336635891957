import { LANGUAGES, VIDEO_TYPES } from '@/ui/shared/config/constants';
import { FORM_TYPES } from '@/ui/shared/types/types';
import { z } from 'zod';

export const birthdayTags = [
  { sku: 'online-birthday-invitations', text: 'Online Birthday Invitations' },
  { sku: 'digital-birthday-invites', text: 'Digital Birthday Invites' },
  { sku: 'custom-birthday-invitations', text: 'Custom Birthday Invitations' },
  { sku: 'birthday-invitation-templates', text: 'Birthday Invitation Templates' },
  { sku: 'online-birthday-wishes', text: 'Online Birthday Wishes' },
  { sku: 'birthday-card-creation', text: 'Birthday Card Creation' },
  { sku: 'virtual-birthday-invitations', text: 'Virtual Birthday Invitations' },
  { sku: 'personalized-birthday-invites', text: 'Personalized Birthday Invites' },
  { sku: 'birthday-invitation-design', text: 'Birthday Invitation Design' },
  { sku: 'e-invitation-for-birthday', text: 'E-Invitation For Birthday' },
  { sku: 'birthday-wishes-online', text: 'Birthday Wishes Online' },
  { sku: 'create-birthday-invitations', text: 'Create Birthday Invitations' },
  { sku: 'birthday-invitation-maker', text: 'Birthday Invitation Maker' },
  { sku: 'diy-birthday-invitations', text: 'DIY Birthday Invitations' },
  { sku: 'birthday-invitation-messages', text: 'Birthday Invitation Messages' },
  { sku: 'e-card-birthday-wishes', text: 'E-Card Birthday Wishes' },
  { sku: 'birthday-greeting-cards-online', text: 'Birthday Greeting Cards Online' },
  { sku: 'online-birthday-party-invitations', text: 'Online Birthday Party Invitations' },
  { sku: 'digital-birthday-greetings', text: 'Digital Birthday Greetings' },
  { sku: 'printable-birthday-invitations', text: 'Printable Birthday Invitations' },
  { sku: 'customizable-birthday-invites', text: 'Customizable Birthday Invites' },
  { sku: 'interactive-birthday-invitations', text: 'Interactive Birthday Invitations' },
  { sku: 'birthday-invitation-cards', text: 'Birthday Invitation Cards' },
  { sku: 'unique-birthday-invitations', text: 'Unique Birthday Invitations' },
  { sku: 'birthday-invitation-ideas', text: 'Birthday Invitation Ideas' },
  { sku: 'birthday-party-e-invites', text: 'Birthday Party E-Invites' },
  { sku: 'personalized-birthday-cards', text: 'Personalized Birthday Cards' },
  { sku: 'virtual-birthday-greetings', text: 'Virtual Birthday Greetings' },
  { sku: 'birthday-wishes-design', text: 'Birthday Wishes Design' },
  { sku: 'custom-birthday-wishes', text: 'Custom Birthday Wishes' },
  { sku: 'online-birthday-party-cards', text: 'Online Birthday Party Cards' },
  { sku: 'birthday-invitation-customization', text: 'Birthday Invitation Customization' },
  { sku: 'digital-birthday-celebration-invites', text: 'Digital Birthday Celebration Invites' },
  { sku: 'birthday-invitation-online', text: 'Birthday Invitation Online' },
  { sku: 'online-birthday-invitation-services', text: 'Online Birthday Invitation Services' },
  { sku: 'birthday-invitation-rsvp', text: 'Birthday Invitation RSVP' },
  { sku: 'custom-digital-birthday-invites', text: 'Custom Digital Birthday Invites' },
  { sku: 'birthday-e-invite-design', text: 'Birthday E-Invite Design' },
  { sku: 'personalized-e-invitations', text: 'Personalized E-Invitations' },
  { sku: 'birthday-invitation-graphics', text: 'Birthday Invitation Graphics' },
  { sku: 'digital-birthday-cards', text: 'Digital Birthday Cards' },
  { sku: 'interactive-birthday-e-cards', text: 'Interactive Birthday E-Cards' },
  { sku: 'birthday-party-invitation-creation', text: 'Birthday Party Invitation Creation' },
  { sku: 'design-birthday-invitations-online', text: 'Design Birthday Invitations Online' },
  { sku: 'birthday-celebration-e-invites', text: 'Birthday Celebration E-Invites' },
  { sku: 'custom-birthday-e-cards', text: 'Custom Birthday E-Cards' },
  { sku: 'digital-birthday-party-invites', text: 'Digital Birthday Party Invites' },
  { sku: 'birthday-invitation-design-tools', text: 'Birthday Invitation Design Tools' },
  { sku: 'online-birthday-wishes-creation', text: 'Online Birthday Wishes Creation' },
  { sku: 'unique-digital-birthday-invitations', text: 'Unique Digital Birthday Invitations' },
  { sku: 'personalized-birthday-invitation-templates', text: 'Personalized Birthday Invitation Templates' },
  { sku: 'virtual-birthday-celebration-cards', text: 'Virtual Birthday Celebration Cards' },
  { sku: 'editable-birthday-invitations', text: 'Editable Birthday Invitations' },
  { sku: 'online-birthday-event-invitations', text: 'Online Birthday Event Invitations' },
  { sku: 'digital-birthday-invite-maker', text: 'Digital Birthday Invite Maker' },
  { sku: 'birthday-invitations-with-rsvp', text: 'Birthday Invitations With RSVP' },
  { sku: 'online-birthday-wishes-messages', text: 'Online Birthday Wishes Messages' },
  { sku: 'customizable-e-birthday-cards', text: 'Customizable E-Birthday Cards' },
  { sku: 'birthday-invitation-creation-platform', text: 'Birthday Invitation Creation Platform' },
  { sku: 'virtual-birthday-card-designs', text: 'Virtual Birthday Card Designs' },
  { sku: 'birthday-e-card-creation-tools', text: 'Birthday E-Card Creation Tools' },
  { sku: 'create-online-birthday-wishes', text: 'Create Online Birthday Wishes' },
  { sku: 'digital-birthday-party-cards', text: 'Digital Birthday Party Cards' },
  { sku: 'custom-birthday-greeting-e-cards', text: 'Custom Birthday Greeting E-Cards' },
  { sku: 'birthday-invitation-designs-online', text: 'Birthday Invitation Designs Online' },
  { sku: 'personalized-digital-birthday-wishes', text: 'Personalized Digital Birthday Wishes' }
];

export const birthdaySchema = z.object({
  type: z.string(),
  language: z.nativeEnum(LANGUAGES),
  color: z.string().optional(),
  font: z.string(),
  name: z.string(),
  message: z.string(),
  image: z.string()
});

export const birthdayInvitationSchema = z.object({
  type: z.string(),
  language: z.nativeEnum(LANGUAGES),
  color: z.string().optional(),
  font: z.string(),
  welcomeMsg: z.string(),
  name: z.string(),
  message: z.string(),
  image: z.string().optional(),
  date: z.string(),
  venue: z.string(),
  invitedBy: z.string()
});

export const birthdayWishesForm = [
  { type: FORM_TYPES.COLOR, key: 'color', label: 'Choose text color' },
  { type: FORM_TYPES.TEXTAREA, key: 'message', label: 'Enter message' },
  { type: FORM_TYPES.FILE, key: 'image', label: 'Select image' }
];

export const birthdayInviteForm = [
  { type: FORM_TYPES.COLOR, key: 'color', label: 'Choose text color' },
  { type: FORM_TYPES.TEXT, key: 'welcomeMsg', label: 'Enter welcome message' },
  { type: FORM_TYPES.TEXT, key: 'name', label: 'Enter title' },
  { type: FORM_TYPES.TEXTAREA, key: 'message', label: 'Enter message' },
  { type: FORM_TYPES.FILE, key: 'image', label: 'Select image' },
  { type: FORM_TYPES.TEXT, key: 'date', label: 'Enter date & time' },
  { type: FORM_TYPES.TEXT, key: 'venue', label: 'Enter venue' },
  { type: FORM_TYPES.TEXT, key: 'invitedBy', label: 'Enter invited by' }
];

export const birthdayWishesLanguages = {
  [LANGUAGES.ENGLISH]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Birthday Wishes',
      description: 'Create Birthday wishes and share with family and friends.',
      message:
        'May you be gifted with life’s biggest joys and never-ending bliss. After all, you yourself are a gift to earth, so you deserve the best. Happy Birthday Zara Agarwal.',
      image: ''
    }
  },
  [LANGUAGES.HINDI]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.HINDI,
      title: 'Birthday Wishes',
      description: 'Create Birthday wishes and share with family and friends.',
      message:
        'आपको जीवन की सबसे बड़ी खुशियाँ और कभी न ख़त्म होने वाला आनंद मिले। आख़िरकार, आप स्वयं पृथ्वी के लिए एक उपहार हैं, इसलिए आप सर्वश्रेष्ठ के पात्र हैं। जन्मदिन मुबारक हो ज़ारा अग्रवाल.',
      image: ''
    }
  },
  [LANGUAGES.TELUGU]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Birthday Wishes',
      description: 'Create Birthday wishes and share with family and friends.',
      message:
        'మీ భవిష్యత్తు మరింత శోభాయమానంగా, ఉన్నతంగా, మీరు మరిన్ని ఉన్నత శిఖరాలు అధిరోహించి, సమున్నతంగా, సంపూర్ణ ఆయురారోగ్యాలతో నిండు నూరేళ్ళు సంతోషంగా వుండాలని ఆశిస్తూ... జార అగర్వాల్ కిి జన్మదిన శుభాకాంక్షలు',
      image: ''
    }
  },
  [LANGUAGES.KANNADA]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.KANNADA,
      title: 'Birthday Wishes',
      description: 'Create Birthday wishes and share with family and friends.',
      message:
        'ನೀವು ಜೀವನದ ಅತ್ಯಂತ ದೊಡ್ಡ ಸಂತೋಷಗಳು ಮತ್ತು ಎಂದಿಗೂ ಅಂತ್ಯವಿಲ್ಲದ ಆನಂದವನ್ನು ನೀಡಲಿ. ಎಲ್ಲಾ ನಂತರ, ನೀವೇ ಭೂಮಿಗೆ ಉಡುಗೊರೆಯಾಗಿರುತ್ತೀರಿ, ಆದ್ದರಿಂದ ನೀವು ಉತ್ತಮ ಅರ್ಹರು. ಜನ್ಮದಿನದ ಶುಭಾಶಯಗಳು ಜರಾ ಅಗರ್ವಾಲ್.',
      image: ''
    }
  },
  [LANGUAGES.TAMIL]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TAMIL,
      title: 'Birthday Wishes',
      description: 'Create Birthday wishes and share with family and friends.',
      message:
        'உங்கள் வாழ்க்கையின் மிகப் பெரிய மகிழ்ச்சிகளும் முடிவிலியாத ஆனந்தமும் உங்களுக்கு வழங்கப் படட்டும். நீங்கள் இந்த பூமிக்கே ஒரு பரிசு, ஆகையால் நீங்கள் சிறந்ததை பெற அன்றாட. பிறந்தநாள் நல்வாழ்த்துகள் ஜாரா அகர்வால்.',
      image: ''
    }
  },
  [LANGUAGES.MALAYALAM]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.MALAYALAM,
      title: 'Birthday Wishes',
      description: 'Create Birthday wishes and share with family and friends.',
      message:
        'ജീവിതത്തിലെ ഏറ്റവും വലിയ സന്തോഷങ്ങളും അനന്തമായ സുഖങ്ങളും നിങ്ങൾക്കു ലഭിക്കട്ടെ. നിങ്ങൾ സ്വയം ഭൂമിക്കൊരു വരമാണല്ലോ, അതിനാൽ നിങ്ങൾ ഏറ്റവും നല്ലതിനെ അർഹിക്കുന്നു. ഹാപ്പി ബർത്ത്‌ഡേ സാറാ അഗർവാൾ.',
      image: ''
    }
  }
};

export const birthdayInvitationLanguages = {
  [LANGUAGES.ENGLISH]: {
    labels: {
      date: 'DATE',
      venue: 'VENUE',
      invitedBy: 'INVITED BY'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      font: '',
      title: 'Birthday Invitation',
      description: 'Create Birthday invitation and share with family and friends.',
      welcomeMsg: 'OM GANESHAYA NAMAH',
      name: 'Birthday Party Invitation',
      message: 'We are celebrating birthday of our cutest daughter Zara Agarwal, Please join us on this ocation.',
      date: 'Friday, February 28th, 2024',
      venue: 'Palace Grounds, Vasanth Nagar, Bangalore.',
      invitedBy: 'Mr. Manish Agarwal & Mrs Anuskha Agarwal'
    }
  },
  [LANGUAGES.HINDI]: {
    labels: {
      date: 'तारीख',
      venue: 'जगह',
      invitedBy: 'निमंत्रक'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.HINDI,
      font: '',
      title: 'Birthday Invitation',
      description: 'Create Birthday invitation and share with family and friends.',
      welcomeMsg: 'ॐ गणेशाय नमः',
      name: 'जन्मदिन की पार्टी का निमंत्रण',
      message: 'हम अपनी सबसे प्यारी बेटी ज़ारा अग्रवाल का जन्मदिन मना रहे हैं, कृपया इस अवसर पर हमारे साथ जुड़ें।',
      date: 'शुक्रवार, 28 फ़रवरी 2024',
      venue: 'पैलेस ग्राउंड्स, वसंत नगर, बैंगलोर।',
      invitedBy: 'श्री. मनीष अग्रवाल & श्रीमती. अनुष्का अग्रवाल'
    }
  },
  [LANGUAGES.TELUGU]: {
    labels: {
      date: 'పుట్టినరోజు తేదీ',
      venue: 'వేదిక',
      invitedBy: 'ఆహ్వానించువారు'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      font: '',
      title: 'Birthday Invitation',
      description: 'Create Birthday invitation and share with family and friends.',
      welcomeMsg: 'ఓం గణేశాయ నమః',
      name: 'అగర్వాల్  వారి పుట్టినరోజు వేడుక ఆహ్వానం',
      message:
        'మా ప్రియమైన కుమార్తె జార అగర్వాల్ పుట్టినరోజు వేడుకను జరుపుకుంటున్నాము. కావున మీ ప్రేమ ఆప్యాయతలు మా కుమారునికి అందించి మా ఆతిథ్యం స్వీకరించ ప్రార్థన.',
      date: 'శుక్రవారం, ఫిబ్రవరి 28 2024',
      venue: 'ప్యాలస్ గ్రౌండ్స్, వసంత్ నగర్, బెంగుళూరు.',
      invitedBy: 'శ్రీ మనీష్ అగర్వాల్ & శ్రీమతి అనుష్క అగర్వాల్'
    }
  },
  [LANGUAGES.KANNADA]: {
    labels: {
      date: 'ದಿನಾಂಕ',
      venue: 'ಸ್ಥಳ',
      invitedBy: 'ಆಹ್ವಾನಿತರು'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.KANNADA,
      font: '',
      title: 'Birthday Invitation',
      description: 'Create Birthday invitation and share with family and friends.',
      welcomeMsg: 'ಓಂ ಗಣೇಶಾಯ ನಮಃ',
      name: 'ಹುಟ್ಟುಹಬ್ಬದ ಪಾರ್ಟಿಯ ಆಹ್ವಾನ',
      message: 'ನಾವು ನಮ್ಮ ಮುದ್ದಾದ ಮಗಳು ಜರಾ ಅಗರ್ವಾಲ್ ಅವರ ಜನ್ಮದಿನವನ್ನು ಆಚರಿಸುತ್ತಿದ್ದೇವೆ, ದಯವಿಟ್ಟು ಈ ಸಮಾರಂಭದಲ್ಲಿ ನಮ್ಮೊಂದಿಗೆ ಸೇರಿಕೊಳ್ಳಿ.',
      date: 'ಶುಕ್ರವಾರ, ಫೆಬ್ರವರಿ 28, 2024',
      venue: 'ಅರಮನೆ ಮೈದಾನ, ವಸಂತ ನಗರ, ಬೆಂಗಳೂರು.',
      invitedBy: 'ಶ್ರೀ ಮನೀಶ್ ಅಗರ್ವಾಲ್ ಮತ್ತು ಶ್ರೀಮತಿ ಅನುಷ್ಕಾ ಅಗರ್ವಾಲ್'
    }
  },
  [LANGUAGES.TAMIL]: {
    labels: {
      date: 'தேதி',
      venue: 'இடம்',
      invitedBy: 'அழைப்பது'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TAMIL,
      font: '',
      title: 'Birthday Invitation',
      description: 'Create Birthday invitation and share with family and friends.',
      welcomeMsg: 'ஓம் கணேசாய நம:',
      name: 'பிறந்தநாள் விழா அழைப்பிதழ்',
      message: 'எங்கள் அழகான மகள் ஜாரா அகர்வாலின் பிறந்தநாளை நாம் கொண்டாடுகிறோம், இந்த நேரத்தில் எங்களுடன் சேர்ந்து கொண்டாடுங்கள்.',
      date: 'வெள்ளி, பிப்ரவரி 28, 2024',
      venue: 'அரண்மனை மைதானம், வசந்த் நகரம், பெங்களூரு.',
      invitedBy: 'திரு. மனீஷ் அகர்வால் & திருமதி. அனுஷ்கா அகர்வால்'
    }
  },
  [LANGUAGES.MALAYALAM]: {
    labels: {
      date: 'തീയതി',
      venue: 'വേദി',
      invitedBy: 'ക്ഷണിച്ചത്'
    },
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.MALAYALAM,
      font: '',
      title: 'Birthday Invitation',
      description: 'Create Birthday invitation and share with family and friends.',
      welcomeMsg: 'ഓം ഗണേശായ നമ:',
      name: 'പിറന്നാൾ പാർട്ടി ക്ഷണം',
      message: 'ഞങ്ങളുടെ പ്രിയപ്പെട്ട മകൾ സാര അഗർവാലിന്റെ പിറന്നാൾ ഞങ്ങൾ ആഘോഷിക്കുന്നു, ഈ അവസരത്തിൽ നിങ്ങൾ ഞങ്ങളെ ചേർന്ന് അനുഗ്രഹിക്കണം.',
      date: 'വെള്ളിയാഴ്ച, ഫെബ്രുവരി 28, 2024',
      venue: 'പാലസ് ഗ്രൗണ്ട്സ്, വസന്ത് നഗർ, ബാംഗ്ലൂർ.',
      invitedBy: 'ശ്രീ മണീഷ് അഗർവാൽ & ശ്രീമതി അനുഷ്ക അഗർവാൽ'
    }
  }
};
