import { create } from 'zustand';
import { immerMiddleware } from '../services/Utils';

const videoStore = set => ({
  video: {
    data: { type: 'web', language: 'english', font: 'Default' }
  },
  template: {},
  setTemplate: template =>
    set(state => {
      state.template = template;
    }),
  resetTemplate: () =>
    set(state => {
      state.template = {};
    }),
  setVideoData: (key: string, value: string) =>
    set(state => {
      state.video.data[key] = value;
    }),
  setDefaultVideoData: data =>
    set(state => {
      state.video.data = data;
    }),
  setVideo: video =>
    set(state => {
      state.video = video;
    }),
  resetVideoData: () =>
    set(state => {
      state.video = { data: {} };
    }),
  setCompositionId: compositionId =>
    set(state => {
      state.video.compositionId = compositionId;
    }),
  setCompositionType: compositionType =>
    set(state => {
      state.video.compositionType = compositionType;
    })
});

export const useVideoStore = create(immerMiddleware(videoStore));
