import { useMemo } from 'react';
import imgHouse from '@/remotion/assets/images/house-transparent.png';
import birthdayMusic from '@/remotion/assets/sounds/birthday.mp3';
import { Image } from '@/remotion/helpers/Image.tsx';
import { Text } from '@/remotion/helpers/Text';
import { Wraper } from '@/remotion/helpers/Wraper.tsx';
import { getTemplateVideo } from '@/ui/services/TemplatesService.ts';
import { templateSkus } from '@/ui/services/TemplateVideosService.ts';
import { interpolate, interpolateColors, spring, useCurrentFrame, useVideoConfig } from 'remotion';

const OfferAdvertisement = (props: any) => {
  const { color = '#fff', name, message, image } = props;
  const template = useMemo(() => getTemplateVideo('advertisements', templateSkus.offerAdvertisement), []);
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const totalFrames = 5 * 24;
  const scale = spring({
    frame,
    fps,
    from: 0.5,
    to: 1,
    config: { mass: 1, damping: 12, stiffness: 100 },
    durationInFrames: totalFrames
  });
  const backgroundColor = interpolateColors(frame, [0, totalFrames * 0.4, totalFrames * 0.8], ['#ff4d4d', '#ff9933', '#4d88ff']);
  const translateY = interpolate(frame, [0, totalFrames * 0.15, totalFrames * 0.35], [180, 0, -10], { extrapolateRight: 'clamp' });
  const rotation = interpolate(frame, [0, totalFrames], [0, 360]);
  const scaleShape = interpolate(frame, [0, totalFrames], [0.4, 1]);
  const imageScale = spring({
    frame: frame - totalFrames * 0.1,
    fps,
    from: 0.5,
    to: 1,
    config: { mass: 1, damping: 10, stiffness: 50 },
    durationInFrames: totalFrames
  });

  return (
    <Wraper color={color} template={template} data={props} music={birthdayMusic}>
      <div className="flex flex-col items-center justify-between p-8 w-full h-full overflow-hidden" style={{ backgroundColor }}>
        <div
          className="absolute w-[600px] h-[600px] bg-white/10 rounded-full"
          style={{ transform: `rotate(${rotation}deg) scale(${scaleShape})`, top: '-100px', right: '-100px' }}
        />
        <Text
          from={5}
          to={10}
          color={color}
          className="text-white font-bold text-[60px] line-clamp-1"
          style={{ transform: `translateY(${translateY}px) scale(${scale})` }}>
          {name}
        </Text>
        <Image
          from={1}
          to={5}
          source={image || imgHouse}
          className="w-[400px] h-[400px] rounded-lg pb-5"
          style={{ transform: `scale(${imageScale})` }}
        />
        <Text className="text-white text-3xl text-center leading-relaxed whitespace-pre-wrap line-clamp-3" from={1} to={5} color={color}>
          {message}
        </Text>
        <div
          className="absolute w-[400px] h-[400px] bg-white/10 rounded-full"
          style={{ transform: `rotate(${rotation}deg) scale(${scaleShape})`, bottom: '-50px', left: '-100px' }}
        />
      </div>
    </Wraper>
  );
};

export default OfferAdvertisement;
