import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../hooks/hooksUtils';
import { useQueryData } from '../hooks/useQueryData';
import { RestService } from './RestService';

export const fetchUser = ({ queryKey }: any) => {
  return RestService.get(`/users/` + queryKey[1]);
};

export const useUserData = (uuid: string) => {
  return useQueryData([QUERY_KEYS.USERS, uuid], fetchUser, { enabled: !!uuid });
};

export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (user: any) => RestService.update('/users/' + user.uuid, user),
    onSuccess: async newData => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USERS, newData.data.uuid] });
    }
  });
};
