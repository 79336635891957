import { offerAnimationForm, offerAnimationLanguages, offerAnimationSchema } from '@/remotion/compositions/advertisements/AdvertisementsSchema';
import {
  birthdayInvitationLanguages,
  birthdayInvitationSchema,
  birthdayInviteForm,
  birthdaySchema,
  birthdayWishesForm,
  birthdayWishesLanguages
} from '@/remotion/compositions/birthday/BirthdaySchema';
import {
  festivalChristmasWishesLanguages,
  festivalDiwaliWishesLanguages,
  festivalHoliWishesLanguages,
  festivalIndependenceWishesLanguages,
  festivalRakhiWishesLanguages,
  festivalRamadanWishesLanguages,
  festivalSchema,
  festivalSnowmanWishesLanguages,
  festivalWishesForm
} from '@/remotion/compositions/festivals/FestivalsSchema';
import {
  houseWarmingInvitationLanguages,
  houseWarmingInvitationSchema,
  houseWarmingInviteForm
} from '@/remotion/compositions/house-warming/HouseWarmingSchema';
import { anniversaryWishesLanguages, lifeEventsSchema, lifeEventsWishesForm } from '@/remotion/compositions/life-events/LifeEventsSchema.ts';
import {
  engagementInvitationLanguages,
  marriageInvitationForm,
  marriageInvitationLanguages,
  marriageSchema
} from '@/remotion/compositions/marriage/MarriageSchema';
import {
  logoAnimationForm,
  logoAnimationLanguages,
  logoAnimationSchema,
  socialMediaCountAnimationForm,
  socialMediaCountAnimationLanguages,
  socialMediaCountAnimationSchema
} from '@/remotion/compositions/technology/TechnologySchema';
import { WATERMARK_POSITION } from '@/shared/config/constants';

export const getTemplateMetaData = (duration: number, web = true, mobile = false, isSquare = false) => {
  let metadata = {};
  if (web) {
    metadata = {
      ...metadata,
      web: {
        compositionWidth: !isSquare ? 1280 : 720,
        compositionHeight: 720,
        compositionDuration: duration
      }
    };
  }
  if (mobile) {
    metadata = {
      ...metadata,
      mobile: {
        compositionWidth: 720,
        compositionHeight: !isSquare ? 1280 : 720,
        compositionDuration: duration
      }
    };
  }
  return metadata;
};

export const templateSkus = {
  // Birthday
  birthdayBalloonsWishes: 'birthday-wishes-with-balloons-animation',
  birthdayFlowersWishes: 'birthday-wishes-with-flowers-animation',
  birthdayVectorsWishes: 'birthday-wishes-with-vectors-animation',
  birthdaySpringsWishes: 'birthday-wishes-with-springs-animation',
  birthdayFlowersInvitation: 'birthday-invitation-with-flowers-animation',
  birthdayVintageInvitation: 'birthday-invitation-with-vintage-animation',
  birthdayLightsInvitation: 'birthday-invitation-with-lights-animation',
  birthdayRotatingFlowersInvitation: 'birthday-invitation-with-rotating-flowers-animation',
  birthdayGarlandsInvitation: 'birthday-invitation-with-garlands-animation',
  // Marriage
  marriageFlowersInvitation: 'marriage-invitation-with-flowers-animation',
  marriageVintageInvitation: 'marriage-invitation-with-vintage-animation',
  marriageLightsInvitation: 'marriage-invitation-with-lights-animation',
  marriageRotatingFlowersInvitation: 'marriage-invitation-with-rotating-flowers-animation',
  marriageGarlandsInvitation: 'marriage-invitation-with-garlands-animation',
  engagementFlowersInvitation: 'engagement-invitation-with-flowers-animation',
  engagementVintageInvitation: 'engagement-invitation-with-vintage-animation',
  engagementLightsInvitation: 'engagement-invitation-with-lights-animation',
  engagementRotatingFlowersInvitation: 'engagement-invitation-with-rotating-flowers-animation',
  engagementGarlandsInvitation: 'engagement-invitation-with-garlands-animation',
  // House Warming
  houseWarmingFlowersInvitation: 'house-warming-invitation-with-flowers-animation',
  houseWarmingVintageInvitation: 'house-warming-invitation-with-vintage-animation',
  houseWarmingLightsInvitation: 'house-warming-invitation-with-lights-animation',
  houseWarmingRotatingFlowersInvitation: 'house-warming-invitation-with-rotating-flowers-animation',
  houseWarmingGarlandsInvitation: 'house-warming-invitation-with-garlands-animation',
  // Festivals
  festivalRakshaBandanWishes: 'festival-wishes-for-raksha-bandan',
  festivalRamadanWishes: 'festival-wishes-for-ramadan',
  festivalChristmasWishes: 'festival-wishes-for-christmas',
  festivalDiwaliWishes: 'festival-wishes-for-diwali',
  festivalDiwaliWithDiyasWishes: 'festival-wishes-for-diwali-with-diyas',
  festivalHoliWishes: 'festival-wishes-for-holi',
  festivalSnowmanWishes: 'festival-wishes-for-snowman',
  festivalIndependenceDayWishes: 'festival-wishes-for-independence-day',
  // Life Events
  anniversaryBalloonsWishes: 'anniversary-wishes-with-balloons-animation',
  anniversaryFlowersWishes: 'anniversary-wishes-with-flowers-animation',
  anniversaryVectorsWishes: 'anniversary-wishes-with-vectors-animation',
  anniversarySpringsWishes: 'anniversary-wishes-with-springs-animation',
  // Technology
  logoRotationAnimation: 'logo-rotation-animation',
  logoHeartBeatAnimation: 'logo-heart-beat-animation',
  logoFadeInAnimation: 'logo-fade-in-animation',
  logoSpringAnimation: 'logo-spring-animation',
  socialMediaCountAnimation: 'social-media-count-animation',
  // Advertisements
  offerAdvertisement: 'offer-advertisement-animation'
};

export const birthdayVideos = [
  {
    name: 'Birthday Invitation with Flowers Animation',
    description: 'Create birthday invitation with flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.birthdayFlowersInvitation,
    form: birthdayInviteForm,
    schema: birthdayInvitationSchema,
    platforms: getTemplateMetaData(290),
    languages: birthdayInvitationLanguages
  },
  {
    name: 'Birthday invitation with vintage animation',
    description: 'Create Birthday invitations with vintage animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.birthdayVintageInvitation,
    form: birthdayInviteForm,
    schema: birthdayInvitationSchema,
    platforms: getTemplateMetaData(290),
    languages: birthdayInvitationLanguages
  },
  {
    name: 'Birthday invitation with lights animation',
    description: 'Create Birthday invitations with lights animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.birthdayLightsInvitation,
    form: birthdayInviteForm,
    schema: birthdayInvitationSchema,
    platforms: getTemplateMetaData(290),
    languages: birthdayInvitationLanguages
  },
  {
    name: 'Birthday invitation with rotating flowers animation',
    description: 'Create Marriage invitations with rotating flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.birthdayRotatingFlowersInvitation,
    form: birthdayInviteForm,
    schema: birthdayInvitationSchema,
    platforms: getTemplateMetaData(290),
    languages: birthdayInvitationLanguages
  },
  {
    name: 'Birthday invitation with garlands animation',
    description: 'Create Birthday invitations with garlands animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.birthdayGarlandsInvitation,
    form: birthdayInviteForm,
    schema: birthdayInvitationSchema,
    platforms: getTemplateMetaData(290),
    languages: birthdayInvitationLanguages
  },
  {
    name: 'Birthday Wishes with Balloons Animation',
    description: 'Create birthday wishes with balloons animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.birthdayBalloonsWishes,
    form: birthdayWishesForm,
    schema: birthdaySchema,
    platforms: getTemplateMetaData(120),
    languages: birthdayWishesLanguages
  },
  {
    name: 'Birthday Wishes with Flowers Animation',
    description: 'Create birthday wishes with flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.birthdayFlowersWishes,
    form: birthdayWishesForm,
    schema: birthdaySchema,
    platforms: getTemplateMetaData(120),
    languages: birthdayWishesLanguages
  },
  {
    name: 'Birthday Wishes with Vectors Animation',
    description: 'Create birthday wishes with vectors animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.birthdayVectorsWishes,
    form: birthdayWishesForm,
    schema: birthdaySchema,
    platforms: getTemplateMetaData(120),
    languages: birthdayWishesLanguages
  },
  {
    name: 'Birthday Wishes with Springs Animation',
    description: 'Create birthday wishes with springs animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.birthdaySpringsWishes,
    form: birthdayWishesForm,
    schema: birthdaySchema,
    platforms: getTemplateMetaData(120),
    languages: birthdayWishesLanguages
  }
];

export const marriageVideos = [
  {
    name: 'Marriage Invitation with Flowers Animation',
    description: 'Create marriage invitation with flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.marriageFlowersInvitation,
    form: marriageInvitationForm,
    schema: marriageSchema,
    platforms: getTemplateMetaData(450),
    languages: marriageInvitationLanguages
  },
  {
    name: 'Marriage invitation with vintage animation',
    description: 'Create Marriage invitations with vintage animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.marriageVintageInvitation,
    form: marriageInvitationForm,
    schema: marriageSchema,
    platforms: getTemplateMetaData(450),
    languages: marriageInvitationLanguages
  },
  {
    name: 'Marriage invitation with lights animation',
    description: 'Create Marriage invitations with lights animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.marriageLightsInvitation,
    form: marriageInvitationForm,
    schema: marriageSchema,
    platforms: getTemplateMetaData(450),
    languages: marriageInvitationLanguages
  },
  {
    name: 'Marriage invitation with flowers animation',
    description: 'Create Marriage invitations with flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.marriageRotatingFlowersInvitation,
    form: marriageInvitationForm,
    schema: marriageSchema,
    platforms: getTemplateMetaData(450),
    languages: marriageInvitationLanguages
  },
  {
    name: 'Marriage invitation with garlands animation',
    description: 'Create Marriage invitations with garlands animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.marriageGarlandsInvitation,
    form: marriageInvitationForm,
    schema: marriageSchema,
    platforms: getTemplateMetaData(450),
    languages: marriageInvitationLanguages
  },
  {
    name: 'Engagement Invitation with Flowers Animation',
    description: 'Create Engagement invitation with flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.engagementFlowersInvitation,
    form: marriageInvitationForm,
    schema: marriageSchema,
    platforms: getTemplateMetaData(450),
    languages: engagementInvitationLanguages
  },
  {
    name: 'Engagement invitation with vintage animation',
    description: 'Create Engagement invitations with vintage animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.engagementVintageInvitation,
    form: marriageInvitationForm,
    schema: marriageSchema,
    platforms: getTemplateMetaData(450),
    languages: engagementInvitationLanguages
  },
  {
    name: 'Engagement invitation with lights animation',
    description: 'Create Engagement invitations with lights animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.engagementLightsInvitation,
    form: marriageInvitationForm,
    schema: marriageSchema,
    platforms: getTemplateMetaData(450),
    languages: engagementInvitationLanguages
  },
  {
    name: 'Engagement invitation with rotating flowers animation',
    description: 'Create Engagement invitations with rotating flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.engagementRotatingFlowersInvitation,
    form: marriageInvitationForm,
    schema: marriageSchema,
    platforms: getTemplateMetaData(450),
    languages: engagementInvitationLanguages
  },
  {
    name: 'Engagement invitation with garlands animation',
    description: 'Create Engagement invitations with garlands animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.engagementGarlandsInvitation,
    form: marriageInvitationForm,
    schema: marriageSchema,
    platforms: getTemplateMetaData(450),
    languages: engagementInvitationLanguages
  }
];

export const houseWarmingVideos = [
  {
    name: 'House Warming Invitation with Flowers Animation',
    description: 'Create house warming invitation with flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.houseWarmingFlowersInvitation,
    form: houseWarmingInviteForm,
    schema: houseWarmingInvitationSchema,
    platforms: getTemplateMetaData(290),
    languages: houseWarmingInvitationLanguages
  },
  {
    name: 'House Warming invitation with vintage animation',
    description: 'Create house warming invitations with vintage animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.houseWarmingVintageInvitation,
    form: houseWarmingInviteForm,
    schema: houseWarmingInvitationSchema,
    platforms: getTemplateMetaData(290),
    languages: houseWarmingInvitationLanguages
  },
  {
    name: 'House Warming invitation with lights animation',
    description: 'Create house warming invitations with lights animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.houseWarmingLightsInvitation,
    form: houseWarmingInviteForm,
    schema: houseWarmingInvitationSchema,
    platforms: getTemplateMetaData(290),
    languages: houseWarmingInvitationLanguages
  },
  {
    name: 'House Warming invitation with rotating flowers animation',
    description: 'Create house warming invitations with rotating flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.houseWarmingRotatingFlowersInvitation,
    form: houseWarmingInviteForm,
    schema: houseWarmingInvitationSchema,
    platforms: getTemplateMetaData(290),
    languages: houseWarmingInvitationLanguages
  },
  {
    name: 'House Warming invitation with garlands animation',
    description: 'Create Birthday invitations with garlands animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.houseWarmingGarlandsInvitation,
    form: houseWarmingInviteForm,
    schema: houseWarmingInvitationSchema,
    platforms: getTemplateMetaData(290),
    languages: houseWarmingInvitationLanguages
  }
];

export const festivalVideos = [
  {
    name: 'Festival Wishes for Raksha Bandhan',
    description: 'Create festival wishes for Raksha bandhan for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.festivalRakshaBandanWishes,
    form: festivalWishesForm,
    schema: festivalSchema,
    platforms: getTemplateMetaData(120),
    languages: festivalRakhiWishesLanguages
  },
  {
    name: 'Festival Wishes for Holi',
    description: 'Create festival wishes for Holi for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.festivalHoliWishes,
    form: festivalWishesForm,
    schema: festivalSchema,
    platforms: getTemplateMetaData(120),
    languages: festivalHoliWishesLanguages
  },
  {
    name: 'Festival Wishes for Christmas',
    description: 'Create festival wishes for Christmas for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMCENTER,
    sku: templateSkus.festivalChristmasWishes,
    form: festivalWishesForm,
    schema: festivalSchema,
    platforms: getTemplateMetaData(120),
    languages: festivalChristmasWishesLanguages
  },
  {
    name: 'Festival Wishes for Diwali',
    description: 'Create festival wishes for Diwali for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.festivalDiwaliWishes,
    form: festivalWishesForm,
    schema: festivalSchema,
    platforms: getTemplateMetaData(120),
    languages: festivalDiwaliWishesLanguages
  },
  {
    name: 'Festival Wishes for Diwali with diyas',
    description: "Create festival wishes for Diwali with diya's for your friends and family.",
    watermarkPosition: WATERMARK_POSITION.BOTTOMRIGHT,
    sku: templateSkus.festivalDiwaliWithDiyasWishes,
    form: festivalWishesForm,
    schema: festivalSchema,
    platforms: getTemplateMetaData(120),
    languages: festivalDiwaliWishesLanguages
  },
  {
    name: 'Festival Wishes for Ramadan',
    description: 'Create festival wishes for Ramadan for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.festivalRamadanWishes,
    form: festivalWishesForm,
    schema: festivalSchema,
    platforms: getTemplateMetaData(120),
    languages: festivalRamadanWishesLanguages
  },
  {
    name: 'Festival Wishes for Independence Day',
    description: 'Create festival wishes for Independence Day for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.festivalIndependenceDayWishes,
    form: festivalWishesForm,
    schema: festivalSchema,
    platforms: getTemplateMetaData(120),
    languages: festivalIndependenceWishesLanguages
  },
  {
    name: 'Festival Wishes for Snowman',
    description: 'Create festival wishes for Snowman for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.festivalSnowmanWishes,
    form: festivalWishesForm,
    schema: festivalSchema,
    platforms: getTemplateMetaData(120),
    languages: festivalSnowmanWishesLanguages
  }
];

export const kidsVideos = [...birthdayVideos];

export const lifeEventsVideos = [
  {
    name: 'Anniversary Wishes with Balloons Animation',
    description: 'Create anniversary wishes with balloons animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPLEFT,
    sku: templateSkus.anniversaryBalloonsWishes,
    form: lifeEventsWishesForm,
    schema: lifeEventsSchema,
    platforms: getTemplateMetaData(120),
    languages: anniversaryWishesLanguages
  },
  {
    name: 'Anniversary Wishes with Flowers Animation',
    description: 'Create anniversary wishes with flowers animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.anniversaryFlowersWishes,
    form: lifeEventsWishesForm,
    schema: lifeEventsSchema,
    platforms: getTemplateMetaData(120),
    languages: anniversaryWishesLanguages
  },
  {
    name: 'Anniversary Wishes with Vectors Animation',
    description: 'Create anniversary wishes with vectors animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPCENTER,
    sku: templateSkus.anniversaryVectorsWishes,
    form: lifeEventsWishesForm,
    schema: lifeEventsSchema,
    platforms: getTemplateMetaData(120),
    languages: anniversaryWishesLanguages
  },
  {
    name: 'Anniversary Wishes with Springs Animation',
    description: 'Create anniversary wishes with springs animation for your friends and family.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.anniversarySpringsWishes,
    form: lifeEventsWishesForm,
    schema: lifeEventsSchema,
    platforms: getTemplateMetaData(120),
    languages: anniversaryWishesLanguages
  }
];

export const technologyVideos = [
  {
    name: 'Social Media Count Animation',
    description: 'Create social media count animation video based on your specific needs.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.socialMediaCountAnimation,
    form: socialMediaCountAnimationForm,
    schema: socialMediaCountAnimationSchema,
    platforms: getTemplateMetaData(120),
    languages: socialMediaCountAnimationLanguages
  },
  {
    name: 'Logo Rotation Animation',
    description: 'Create logo animation based on your specific needs.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.logoRotationAnimation,
    form: logoAnimationForm,
    schema: logoAnimationSchema,
    platforms: getTemplateMetaData(72, true, true),
    languages: logoAnimationLanguages
  },
  {
    name: 'Logo Heart Beat Animation',
    description: 'Create logo animation based on your specific needs.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.logoHeartBeatAnimation,
    form: logoAnimationForm,
    schema: logoAnimationSchema,
    platforms: getTemplateMetaData(72, true, true),
    languages: logoAnimationLanguages
  },
  {
    name: 'Logo Fade In Animation',
    description: 'Create logo animation based on your specific needs.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.logoFadeInAnimation,
    form: logoAnimationForm,
    schema: logoAnimationSchema,
    platforms: getTemplateMetaData(72, true, true),
    languages: logoAnimationLanguages
  },
  {
    name: 'Logo Spring Animation',
    description: 'Create logo animation based on your specific needs.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.logoSpringAnimation,
    form: logoAnimationForm,
    schema: logoAnimationSchema,
    platforms: getTemplateMetaData(72, true, true),
    languages: logoAnimationLanguages
  }
];

export const advertisementVideos = [
  {
    name: 'Offer Advertisement Animation',
    description: 'Create advertisement animations based on your specific needs.',
    watermarkPosition: WATERMARK_POSITION.TOPRIGHT,
    sku: templateSkus.offerAdvertisement,
    form: offerAnimationForm,
    schema: offerAnimationSchema,
    platforms: getTemplateMetaData(120, true, false, true),
    languages: offerAnimationLanguages
  }
];
