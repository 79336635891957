import { WATERMARK_POSITION } from '@/ui/shared/config/constants';
import { staticFile } from 'remotion';
import { Image } from './Image';
import { Text } from './Text';

const classesMapping = {
  [WATERMARK_POSITION.TOPLEFT]: 'top-0 left-0',
  [WATERMARK_POSITION.TOPRIGHT]: 'top-0 right-0',
  [WATERMARK_POSITION.TOPCENTER]: 'top-0 left-0 right-0 flex justify-center',
  [WATERMARK_POSITION.BOTTOMLEFT]: 'bottom-0 left-0',
  [WATERMARK_POSITION.BOTTOMRIGHT]: 'bottom-0 right-0',
  [WATERMARK_POSITION.BOTTOMCENTER]: 'bottom-0 left-0 right-0 flex justify-center'
};

interface Props {
  template: any;
  color?: string;
}

export const Watermark = (props: Props) => {
  const { template } = props;

  return (
    <div className={`absolute px-5 py-3 ${classesMapping[template?.watermarkPosition] || classesMapping[WATERMARK_POSITION.TOPRIGHT]}`}>
      <div className="flex flex-row items-center" style={{ fontFamily: 'Inter, san-serf' }}>
        <Image from={0} to={5} source={staticFile('/logo-small.png')} className="mr-2 w-[60px] h-[60px]" />
        <div>
          <Text from={0} to={5} className="text-[23px] -mt-[5px] font-semibold text-black" color="#005ee6">
            www.inyter.com
          </Text>
          <Text from={0} to={5} className="text-[18px] -mt-[3px] font-semibold text-black" color="#005ee6">
            Invitations & Wishes
          </Text>
        </div>
      </div>
    </div>
  );
};
