import { Link } from 'react-router-dom';
import Bubbles from './Bubbles';

const Banner = () => {
  return (
    <section className="main bg-card flex items-center justify-center overflow-hidden" id="home">
      <div className="container mx-auto px-3 sm:px-0 py-3 sm:py-0">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-8">
          <div className="flex flex-col justify-center">
            <h1 className="text-2xl my-3 text">Create Online Video Invitations and Wishes</h1>
            <div className="text-base leading-loose mb-8 text">
              Create stunning online video invitations and heartfelt wishes effortlessly. Personalize your videos with unique templates, music, and
              messages. Perfect for weddings, birthdays, baby showers, and more. Make every occasion special with our easy-to-use video creation
              tools. Share memorable moments with friends and family. Start creating today!
            </div>
            <div className="p-0">
              <Link to="/templates" className="bg-primary text-white rounded border-primary px-3 py-3 hover:bg-primary-dark">
                START CREATING
              </Link>
            </div>
          </div>
          <Bubbles>
            <>
              <div className="p-5 bg-white rounded-lg flex items-center justify-between space-x-8">
                <div className="flex-1">
                  <div className="h-4 md:w-48 w-36 bg-gray-300 rounded" />
                </div>
                <div>
                  <div className="md:w-24 w-16 h-6 rounded-lg bg-purple-300" />
                </div>
              </div>
              <div className="p-5 bg-white rounded-lg flex items-center justify-between space-x-8">
                <div className="flex-1">
                  <div className="h-4 md:w-56 w-30 bg-gray-300 rounded" />
                </div>
                <div>
                  <div className="md:w-16 w-12 h-6 rounded-lg bg-yellow-300" />
                </div>
              </div>
              <div className="p-5 bg-white rounded-lg flex items-center justify-between space-x-8">
                <div className="flex-1">
                  <div className="h-4 w-44 bg-gray-300 rounded" />
                </div>
                <div>
                  <div className="md:w-28 w-20 h-6 rounded-lg bg-pink-300" />
                </div>
              </div>
              <div className="p-5 bg-white rounded-lg flex items-center justify-between space-x-8">
                <div className="flex-1">
                  <div className="h-4 w-44 bg-gray-300 rounded" />
                </div>
                <div>
                  <div className="md:w-24 w-12 h-6 rounded-lg bg-green-300" />
                </div>
              </div>
            </>
          </Bubbles>
        </div>
      </div>
    </section>
  );
};

export default Banner;
