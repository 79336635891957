import { QUERY_KEYS } from '../hooks/hooksUtils';
import { useQueryData } from '../hooks/useQueryData';
import { RestService } from './RestService';

export const fetchPayments = () => {
  return RestService.get(`/payments`);
};

export const usePaymentsData = () => {
  return useQueryData([QUERY_KEYS.PAYMENTS], fetchPayments);
};
