import { useMemo } from 'react';
import { Wraper } from '@/remotion/helpers/Wraper';
import { getTemplateVideo } from '@/ui/services/TemplatesService';
import { templateSkus } from '@/ui/services/TemplateVideosService';
import { useCurrentFrame } from 'remotion';
import { z } from 'zod';
import { heartBeatEffect } from '../../services/utils';
import LogoBaseAnimation from './LogoBaseAnimation';
import { logoAnimationSchema } from './TechnologySchema';

const LogoRotationAnimation: React.FC<z.infer<typeof logoAnimationSchema>> = props => {
  const frame = useCurrentFrame();
  const scale = heartBeatEffect(frame % 60, 60);
  const template = useMemo(() => getTemplateVideo('technology', templateSkus.logoRotationAnimation), []);

  return (
    <Wraper color={props.color} template={template} data={props}>
      <LogoBaseAnimation {...props} animationStyle={{ transform: `scale(${scale})` }} />
    </Wraper>
  );
};

export default LogoRotationAnimation;
