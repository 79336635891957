import { LANGUAGES, VIDEO_TYPES } from '@/shared/config/constants';
import { FORM_TYPES } from '@/shared/types/types';
import { z } from 'zod';

export const advertisementTags = [
  { sku: 'advertisement-video-creation', text: 'Advertisement Video Creation' },
  { sku: 'ad-video-production', text: 'Ad Video Production' },
  { sku: 'professional-ad-videos', text: 'Professional Ad Videos' },
  { sku: 'create-ads-for-social-media', text: 'Create Ads For Social Media' },
  { sku: 'product-promotion-videos', text: 'Product Promotion Videos' },
  { sku: 'custom-advertisement-videos', text: 'Custom Advertisement Videos' },
  { sku: 'marketing-video-production', text: 'Marketing Video Production' },
  { sku: 'digital-ad-video', text: 'Digital Ad Video' },
  { sku: 'youtube-ad-videos', text: 'YouTube Ad Videos' },
  { sku: 'facebook-ad-video-creation', text: 'Facebook Ad Video Creation' },
  { sku: 'instagram-ad-videos', text: 'Instagram Ad Videos' },
  { sku: 'video-ads-for-businesses', text: 'Video Ads For Businesses' },
  { sku: 'corporate-ad-video-production', text: 'Corporate Ad Video Production' },
  { sku: 'video-advertising-services', text: 'Video Advertising Services' },
  { sku: 'high-quality-ad-video-production', text: 'High-Quality Ad Video Production' },
  { sku: 'video-marketing-services', text: 'Video Marketing Services' },
  { sku: 'online-video-ads', text: 'Online Video Ads' },
  { sku: 'animated-advertisement-videos', text: 'Animated Advertisement Videos' },
  { sku: 'ecommerce-video-ads', text: 'Ecommerce Video Ads' },
  { sku: 'branding-videos-for-businesses', text: 'Branding Videos For Businesses' },
  { sku: 'real-estate-video-ads', text: 'Real Estate Video Ads' },
  { sku: 'fashion-product-video-ads', text: 'Fashion Product Video Ads' },
  { sku: 'event-promotion-videos', text: 'Event Promotion Videos' },
  { sku: 'app-promotion-video-ads', text: 'App Promotion Video Ads' },
  { sku: 'social-media-ad-campaigns', text: 'Social Media Ad Campaigns' },
  { sku: 'google-ad-videos', text: 'Google Ad Videos' },
  { sku: 'linkedin-video-ads', text: 'LinkedIn Video Ads' },
  { sku: 'tiktok-ad-videos', text: 'TikTok Ad Videos' },
  { sku: 'custom-animated-ads', text: 'Custom Animated Ads' },
  { sku: 'short-form-video-ads', text: 'Short-Form Video Ads' },
  { sku: 'video-content-for-ads', text: 'Video Content For Ads' },
  { sku: 'business-video-commercials', text: 'Business Video Commercials' },
  { sku: 'motion-graphics-video-ads', text: 'Motion Graphics Video Ads' },
  { sku: 'video-ads-for-startups', text: 'Video Ads For Startups' },
  { sku: 'lead-generation-video-ads', text: 'Lead Generation Video Ads' },
  { sku: 'creative-video-advertisements', text: 'Creative Video Advertisements' },
  { sku: 'viral-video-ad-production', text: 'Viral Video Ad Production' },
  { sku: 'advertisement-editing-services', text: 'Advertisement Editing Services' },
  { sku: 'post-production-for-ad-videos', text: 'Post-Production For Ad Videos' },
  { sku: 'retail-advertisement-videos', text: 'Retail Advertisement Videos' },
  { sku: 'video-ads-for-automotive-industry', text: 'Video Ads For Automotive Industry' },
  { sku: 'travel-and-tourism-video-ads', text: 'Travel And Tourism Video Ads' },
  { sku: 'healthcare-video-ads', text: 'Healthcare Video Ads' },
  { sku: 'technology-product-ad-videos', text: 'Technology Product Ad Videos' },
  { sku: 'financial-services-video-ads', text: 'Financial Services Video Ads' },
  { sku: 'education-video-advertisements', text: 'Education Video Advertisements' },
  { sku: 'testimonial-video-ads', text: 'Testimonial Video Ads' },
  { sku: 'sponsored-content-videos', text: 'Sponsored Content Videos' },
  { sku: 'cinematic-ad-video-production', text: 'Cinematic Ad Video Production' },
  { sku: 'luxury-brand-video-ads', text: 'Luxury Brand Video Ads' },
  { sku: 'restaurant-ad-videos', text: 'Restaurant Ad Videos' },
  { sku: 'food-and-beverage-video-ads', text: 'Food And Beverage Video Ads' },
  { sku: 'video-content-for-paid-ads', text: 'Video Content For Paid Ads' },
  { sku: 'outdoor-advertisement-videos', text: 'Outdoor Advertisement Videos' },
  { sku: '3d-animation-ads', text: '3D Animation Ads' },
  { sku: 'video-ads-for-crowdfunding-campaigns', text: 'Video Ads For Crowdfunding Campaigns' },
  { sku: 'fashion-and-lifestyle-video-ads', text: 'Fashion And Lifestyle Video Ads' },
  { sku: 'b2b-video-ads', text: 'B2B Video Ads' },
  { sku: 'video-ads-for-mobile-apps', text: 'Video Ads For Mobile Apps' },
  { sku: 'holiday-sales-video-ads', text: 'Holiday Sales Video Ads' },
  { sku: 'seasonal-promotion-video-ads', text: 'Seasonal Promotion Video Ads' },
  { sku: 'black-friday-video-ads', text: 'Black Friday Video Ads' },
  { sku: 'new-product-launch-video-ads', text: 'New Product Launch Video Ads' },
  { sku: 'subscription-service-video-ads', text: 'Subscription Service Video Ads' },
  { sku: 'recruitment-ad-videos', text: 'Recruitment Ad Videos' },
  { sku: 'video-ads-for-software-companies', text: 'Video Ads For Software Companies' },
  { sku: 'engaging-video-advertisements', text: 'Engaging Video Advertisements' },
  { sku: 'emotional-storytelling-video-ads', text: 'Emotional Storytelling Video Ads' },
  { sku: 'influencer-collaboration-video-ads', text: 'Influencer Collaboration Video Ads' },
  { sku: 'direct-response-video-ads', text: 'Direct Response Video Ads' },
  { sku: 'retargeting-video-ads', text: 'Retargeting Video Ads' },
  { sku: 'streaming-platform-ad-videos', text: 'Streaming Platform Ad Videos' },
  { sku: 'broadcast-commercial-video-production', text: 'Broadcast Commercial Video Production' },
  { sku: 'ott-platform-video-ads', text: 'OTT Platform Video Ads' },
  { sku: 'interactive-video-ads', text: 'Interactive Video Ads' },
  { sku: 'personalized-video-ad-creation', text: 'Personalized Video Ad Creation' },
  { sku: 'customer-testimonial-ad-videos', text: 'Customer Testimonial Ad Videos' },
  { sku: 'live-action-advertisement-videos', text: 'Live-Action Advertisement Videos' },
  { sku: 'custom-promotional-video-content', text: 'Custom Promotional Video Content' },
  { sku: 'subscription-box-ad-videos', text: 'Subscription Box Ad Videos' },
  { sku: 'advertising-video-for-retail-stores', text: 'Advertising Video For Retail Stores' }
];

export const offerAnimationSchema = z.object({
  type: z.string(),
  language: z.nativeEnum(LANGUAGES),
  name: z.string(),
  message: z.string(),
  image: z.string()
});

export const offerAnimationForm = [
  { type: FORM_TYPES.COLOR, key: 'color', label: 'Choose text color' },
  { type: FORM_TYPES.PNG, key: 'image', label: 'Select image' },
  { type: FORM_TYPES.TEXT, key: 'name', label: 'Enter offer text' },
  { type: FORM_TYPES.TEXTAREA, key: 'message', label: 'Enter message' }
];

export const offerAnimationLanguages = {
  [LANGUAGES.ENGLISH]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Offer Advertisement Animation',
      description: 'Create Offer Advertisement Animation and Share it on anywhere you like.',
      name: '50% OFF!',
      message: 'Best housing Company in Bangalore.\nBuy Now @ Housing Corp',
      image: ''
    }
  }
};
