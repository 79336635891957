import { useInfiniteQuery } from '@tanstack/react-query';
import { getNextPageParam } from './hooksUtils';

export const useInfiniteQueryData = (queryKey: any, queryFn: any, options: any = {}) => {
  return useInfiniteQuery({
    queryKey,
    queryFn,
    initialPageParam: 1,
    ...Object.assign(
      {
        getNextPageParam: (lastPage: any, pages: any) => getNextPageParam(lastPage, pages)
      },
      options
    )
  });
};
