import logo from '@/remotion/assets/images/logo.png';
import { Center } from '@/remotion/helpers/Center';
import { Image } from '@/remotion/helpers/Image';
import { Text } from '@/remotion/helpers/Text';

const LogoBaseAnimation = (props: any) => {
  const { color = '#000000', bgColor = '#ffffff', name, message, image, animationStyle = {} } = props;

  return (
    <Center className="w-full h-full bg-white" style={{ backgroundColor: bgColor }}>
      <Image source={image || logo} className="w-[130px] h-[130px] mb-2" from={5} to={30} style={animationStyle} />
      {!!name && (
        <Text className="text-[40px] mb-2" from={20} to={50} color={color}>
          {name}
        </Text>
      )}
      {!!message && (
        <Text className="text-[30px]" from={40} to={70} color={color}>
          {message}
        </Text>
      )}
    </Center>
  );
};

export default LogoBaseAnimation;
