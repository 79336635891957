import SubHeader from '@/ui/components/helpers/SubHeader';
import { NLink } from 'nayan';

const PrivacyPolicies = () => {
  return (
    <div className="container mx-auto px-3 sm:px-0">
      <SubHeader title="Privacy Policies" />
      <div className="mb-5">
        Protecting your privacy is important to us. This Privacy Policy explains how Inyter collects, uses, and protects your personal information
        when you use our video invitation and wishes creating application. By accessing or using Inyter, you agree to the terms of this Privacy
        Policy.
      </div>

      <h2 className="mb-5 text-base font-semibold">1. Information We Collect:</h2>
      <div className="mb-5">
        <strong>Personal Information:</strong> When you create an account or use certain features of Inyter, we may collect personal information such
        as your name, email address, and contact information.
      </div>
      <div className="mb-5">
        <strong>User Content:</strong> We collect the video invitations and wishes you create using Inyter, as well as any other content you upload or
        share on the platform.
      </div>
      <div className="mb-5">
        <strong>Usage Information:</strong> We may collect information about how you use Inyter, such as your interactions with the application, the
        features you use, and your preferences.
      </div>
      <div className="mb-5">
        <strong>Device Information:</strong> We may collect information about the device you use to access Inyter, including the device type,
        operating system, and unique device identifiers.
      </div>
      <h2 className="mb-5 text-base font-semibold">2. How We Use Your Information:</h2>
      <div className="mb-5">
        <strong>Providing Services:</strong> We use your personal information to provide and personalize the services offered by Inyter, including
        creating and sending video invitations and wishes.
      </div>
      <div className="mb-5">
        <strong>Communication:</strong> We may use your contact information to communicate with you about your account, updates to our services, or
        promotional offers.
      </div>
      <div className="mb-5">
        <strong>Analytics:</strong> We may use usage information and analytics tools to analyze trends, track user activity, and improve the
        performance of Inyter.
      </div>
      <div className="mb-5">
        <strong>Legal Compliance:</strong> We may use your information to comply with applicable laws, regulations, or legal processes, or to protect
        our rights or the rights of others.
      </div>
      <h2 className="mb-5 text-base font-semibold">3. Information Sharing and Disclosure:</h2>
      <div className="mb-5">
        <strong>Third-Party Service Providers:</strong> We may share your information with third-party service providers who help us operate,
        maintain, or improve Inyter, such as hosting providers, analytics providers, or customer support services.
      </div>
      <div className="mb-5">
        <strong>Legal Requirements:</strong> We may disclose your information in response to a subpoena, court order, or other legal request, or to
        protect the rights, property, or safety of Inyter, our users, or others.
      </div>
      <div className="mb-5">
        <strong>Business Transfers:</strong> If Inyter is involved in a merger, acquisition, or sale of all or a portion of its assets, your
        information may be transferred as part of the transaction.
      </div>
      <h2 className="mb-5 text-base font-semibold">4. Data Security:</h2>
      <div className="mb-5">
        We take reasonable measures to protect the security of your personal information and prevent unauthorized access, disclosure, or alteration.
      </div>
      <div className="mb-5">
        However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.
      </div>
      <h2 className="mb-5 text-base font-semibold">5. Children's Privacy:</h2>
      <div className="mb-5">
        Inyter is not intended for children under the age of 13, and we do not knowingly collect personal information from children under 13. If you
        believe that we have inadvertently collected personal information from a child under 13, please contact us immediately.
      </div>
      <h2 className="mb-5 text-base font-semibold">6. Changes to this Privacy Policy:</h2>
      <div className="mb-5">
        We reserve the right to update or modify this Privacy Policy at any time. If we make material changes, we will notify you by email or by
        posting a notice on Inyter prior to the change becoming effective.
      </div>
      <h2 className="mb-5 text-base font-semibold">7. Contact Us:</h2>
      <div className="mb-5">
        If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at{' '}
        <NLink href="mailto:hello@inyter.com" target="_blank">
          hello@inyter.com
        </NLink>
        .
      </div>
    </div>
  );
};

export default PrivacyPolicies;
