import { NCard } from 'nayan';
import { Link } from 'react-router-dom';

interface Props {
  template: any;
  video: any;
}

export const TemplatesItem = (props: Props) => {
  const { template, video } = props;
  return (
    <Link to={`/templates/${template.sku}/${video.sku}`}>
      <NCard className="col-span-1 flex flex-col h-full shadow">
        <div className="relative h-0 overflow-hidden" style={{ paddingTop: '52.3%' }}>
          <img
            src={`/images/templates/${video.sku}.jpg`}
            alt={video.name}
            className="absolute top-0 left-0 w-full h-full bg-background rounded"
            loading="lazy"
          />
        </div>
        <div className="p-2">
          <div className="text-base mb-1 line-clamp-1">{video.name}</div>
          <div className="text-sm line-clamp-3">{video.description}</div>
        </div>
      </NCard>
    </Link>
  );
};
