import { useLocalStorage } from 'nayan';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoutes = () => {
  const [uuid] = useLocalStorage('UUID', '');
  const currentLocation = typeof location !== 'undefined' ? location?.pathname + location?.search : null;

  return !!uuid ? <Outlet /> : <Navigate to={`/login?redirect=${currentLocation}`} />;
};

export default ProtectedRoutes;
