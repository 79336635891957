import { Suspense } from 'react';
import { useVideoStore } from '@/shared/stores/VideoStore';
import { compositionMapping } from '@/ui/services/TemplatesService';
import { Player } from '@remotion/player';
import { NLoading } from 'nayan';

interface Props {
  template: any;
}

const VideosPlayer = (props: Props) => {
  const { template } = props;
  const videoState = useVideoStore(state => state.video);
  const type = videoState.data.type || 'web';

  if (!template?.platforms) {
    return null;
  }

  return (
    <Suspense fallback={<NLoading />}>
      <Player
        fps={30}
        loop
        controls
        autoPlay={false}
        alwaysShowControls
        inputProps={videoState.data}
        component={compositionMapping[template.sku + '-' + type]}
        className="!w-full !max-w-full !h-[250px] md:!h-[calc(100vh_-_109px)]"
        durationInFrames={template.platforms[type].compositionDuration}
        compositionWidth={template.platforms[type].compositionWidth}
        compositionHeight={template.platforms[type].compositionHeight}
      />
    </Suspense>
  );
};

export default VideosPlayer;
