import { cn } from 'nayan';

interface Props {
  className?: string;
  style?: any;
  children: any;
}

export const Center = (props: Props) => {
  return (
    <div className={cn(`w-full h-full flex flex-col justify-center items-center ${props.className}`)} style={props.style || {}}>
      {props.children}
    </div>
  );
};
