import { Img, useCurrentFrame } from 'remotion';
import { fadeInEffect } from '../services/utils';

interface Props {
  source: string;
  from: number;
  to: number;
  width?: number;
  height?: number;
  className?: string;
  style?: any;
}

export const Image = (props: Props) => {
  const { source, from, to, className, style = {} } = props;
  const frame = useCurrentFrame();
  const opacity = fadeInEffect(frame, from, to);

  return (
    <div style={{ opacity }}>
      <Img src={source} className={className} style={style} />
    </div>
  );
};
