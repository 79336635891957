import { Easing, interpolate, useCurrentFrame } from 'remotion';
import { Text } from './Text';

const AnimatedNumber = ({ start = 0, end, from = 0, to = 5, durationInFrames = 48, className = '', color = '#000' }) => {
  const frame = useCurrentFrame();

  const currentNumber = interpolate(frame, [0, durationInFrames], [start, end], {
    extrapolateRight: 'clamp',
    easing: Easing.linear
  });

  return (
    <Text className={className} from={from} to={to} color={color}>
      {Math.floor(currentNumber)}
    </Text>
  );
};

export default AnimatedNumber;
