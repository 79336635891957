import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon
} from 'react-share';

interface Props {
  video: any;
}

export const VideosShare = (props: Props) => {
  const { video } = props;
  const shareUrl = String(window.location);
  const title = 'Found an interesting video on Inyter for ' + video.data.title;

  return (
    <div className="flex items-center p-3">
      <FacebookShareButton url={shareUrl} className="mr-3">
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton url={shareUrl} title={title} className="mr-3">
        <XIcon size={32} round />
      </TwitterShareButton>
      <LinkedinShareButton url={shareUrl} className="mr-3">
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
      <EmailShareButton url={shareUrl} subject={title} body="body" className="mr-3">
        <EmailIcon size={32} round />
      </EmailShareButton>
      <WhatsappShareButton url={shareUrl} title={title} separator=" :: " className="mr-3">
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <TelegramShareButton url={shareUrl} title={title} className="mr-3">
        <TelegramIcon size={32} round />
      </TelegramShareButton>
    </div>
  );
};
