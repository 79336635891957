import { ACCOUNT_TABS } from '@/shared/types/types';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { AccountDeleteData } from './AccountDeleteData';
import AccountMenu from './AccountMenu';
import AccountNotifications from './AccountNotifications';
import { AccountPassword } from './AccountPassword';
import { AccountProfile } from './AccountProfile';
import AccountSubscriptions from './AccountSubscriptions';

const Account = () => {
  const navigate = useNavigate();
  const params: any = useParams();
  const selectedTab = params.tab || ACCOUNT_TABS.PROFILE;

  const onChangeTab = (tab: ACCOUNT_TABS) => {
    navigate('/account/' + tab);
  };

  const isActive = (tab: ACCOUNT_TABS) => {
    return tab === selectedTab;
  };

  return (
    <div className="container mx-auto px-3 md:px-0">
      <div className="grid grid-cols-9 gap-3 mb-3">
        <div className="col-span-9 md:col-span-2">
          <AccountMenu selectedTab={selectedTab} changeTab={onChangeTab} />
        </div>
        <div className="col-span-9 md:col-span-7">
          {isActive(ACCOUNT_TABS.PROFILE) && <AccountProfile />}
          {isActive(ACCOUNT_TABS.NOTIFICATIONS) && <AccountNotifications />}
          {isActive(ACCOUNT_TABS.SUBSCRIPTIONS) && <AccountSubscriptions />}
          {isActive(ACCOUNT_TABS.CHANGE_PASSWORD) && <AccountPassword />}
          {isActive(ACCOUNT_TABS.DELETE_ACCOUNT) && <AccountDeleteData />}
        </div>
      </div>
    </div>
  );
};

export default Account;
