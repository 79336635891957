export const APP = {
  name: 'inyter',
  displayName: 'Inyter',
  source: 'app',
  url: 'https://www.inyter.com',
  api: 'https://api.inyter.com',
  socket: 'wss://api.inyter.com',
  files: 'https://d3g533dw0kelxj.cloudfront.net/inyter/',
  payment_key: 'rzp_live_eGa074cKPUGdzO',
  analytics_id: 'G-KNQ382VLBW',
  logo_url: 'https://www.inyter.com/logo.png',
  banner_url: 'https://www.inyter.com/banner.png',
  title: 'Create Online Video Invitations and Wishes',
  description:
    'Create stunning online video invitations and heartfelt wishes effortlessly. Personalize your videos with unique templates, music, and messages. Perfect for weddings, birthdays, baby showers, and more. Make every occasion special with our easy-to-use video creation tools. Share memorable moments with friends and family. Start creating today!',
  keywords:
    'Online video invitations, Custom video invitations, Personalized video invites, Video invitations maker, Create video invitations online, Video greetings, Video wishes creator, Digital invitations, Video invite templates, Birthday video invitations, Wedding video invitations, Baby shower video invites, Event video invitations, Anniversary video wishes, Easy video invitations, Video invitation tool, Online invitation maker, Video greeting'
};

export enum REACTIONS {
  SMILE = 'SMILE',
  TEARS = 'TEARS',
  BLUSH = 'BLUSH',
  SAD = 'SAD',
  ANGRY = 'ANGRY',
  HEART = 'HEART',
  OK = 'OK',
  SUPER = 'SUPER',
  CLAP = 'CLAP',
  HANDS = 'HANDS'
}

export const reactions = [
  { name: REACTIONS.SMILE, icon: '😀' },
  { name: REACTIONS.TEARS, icon: '😂' },
  { name: REACTIONS.BLUSH, icon: '😳' },
  { name: REACTIONS.SAD, icon: '😭' },
  { name: REACTIONS.ANGRY, icon: '😡' },
  { name: REACTIONS.HEART, icon: '💖' },
  { name: REACTIONS.OK, icon: '👍' },
  { name: REACTIONS.SUPER, icon: '👌' },
  { name: REACTIONS.CLAP, icon: '👏' },
  { name: REACTIONS.HANDS, icon: '🙏' }
];

export enum COLOR_NAMES {
  RED = 'RED',
  ORANGE = 'ORANGE',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  CYAN = 'CYAN',
  BLUE = 'BLUE',
  INDIGO = 'INDIGO',
  PURPLE = 'PURPLE',
  PINK = 'PINK'
}

export const COLORS = {
  [COLOR_NAMES.RED]: { light: '#fecaca', dark: '#dc2626' },
  [COLOR_NAMES.ORANGE]: { light: '#fed7aa', dark: '#ea580c' },
  [COLOR_NAMES.YELLOW]: { light: '#fef9c3', dark: '#947103' },
  [COLOR_NAMES.GREEN]: { light: '#bbf7d0', dark: '#16a34a' },
  [COLOR_NAMES.CYAN]: { light: '#a5f3fc', dark: '#0891b2' },
  [COLOR_NAMES.BLUE]: { light: '#bfdbfe', dark: '#2563eb' },
  [COLOR_NAMES.INDIGO]: { light: '#c7d2fe', dark: '#4f46e5' },
  [COLOR_NAMES.PURPLE]: { light: '#e9d5ff', dark: '#9333ea' },
  [COLOR_NAMES.PINK]: { light: '#f5d0fe', dark: '#c026d3' }
};

export const reportTypes = [
  'Harassment',
  'Fraud or Scam',
  'Spam or Misinformation',
  'Hateful speech',
  'Threats or Violence',
  'Self harm or Dangerous',
  'Sexual content',
  'Fake content',
  'Child exploitation',
  'Other'
];

export enum LANGUAGES {
  ENGLISH = 'english',
  HINDI = 'hindi',
  TELUGU = 'telugu',
  KANNADA = 'kannada',
  TAMIL = 'tamil',
  MALAYALAM = 'malayalam'
}

export enum VIDEO_TYPES {
  WEB = 'web',
  MOBILE = 'mobile'
}

export enum WATERMARK_POSITION {
  TOPLEFT = 'TOPLEFT',
  TOPRIGHT = 'TOPRIGHT',
  TOPCENTER = 'TOPCENTER',
  BOTTOMLEFT = 'BOTTOMLEFT',
  BOTTOMRIGHT = 'BOTTOMRIGHT',
  BOTTOMCENTER = 'BOTTOMCENTER'
}
