import { CONFIG } from '@/shared/config/config';
import { LANGUAGES } from '@/shared/config/constants.ts';
import { RestService } from '@/shared/services/RestService';
import { useUserStore } from '@/shared/stores/UserStore';
import { GoogleFont } from '@remotion/google-fonts';
import Resizer from 'react-image-file-resizer';
import io from 'socket.io-client';
import { StorageService } from './StorageService';

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const resizeImage = async (file: any, maxWidth: number = 512, maxHeight: number = 512) => {
  return new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      'JPEG',
      70,
      0,
      uri => {
        resolve(uri);
      },
      'base64'
    );
  });
};

export const convertPngToBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(new Error('Error reading file'));
    };
    reader.readAsDataURL(file);
  });
};

export const setUserFromStorage = async () => {
  const UUID = StorageService.get('UUID');
  const TOKEN = StorageService.get('TOKEN');
  const UNAME = StorageService.get('UNAME');
  const UEMAIL = StorageService.get('UEMAIL');
  const UMOBILE = StorageService.get('UMOBILE');
  const UABOUT = StorageService.get('UABOUT');
  const UDOB = StorageService.get('UDOB');
  RestService.setToken(TOKEN);
  return useUserStore.setState({ UUID, UNAME, UEMAIL, UMOBILE, UABOUT, UDOB, TOKEN });
};

export const toFixed = (num: number, prc: number = 2) => {
  return num.toFixed(prc);
};

export const socketConnect = () => {
  const TOKEN = useUserStore.getState().TOKEN;
  return io(CONFIG.SOCKET_URL + '?token=' + TOKEN, {
    transports: ['websocket']
  });
};

export const topTextFonts = {
  [LANGUAGES.ENGLISH]: [
    { family: 'Default', load: () => undefined },
    {
      family: 'Inter',
      load: () => import('@remotion/google-fonts/Inter') as Promise<GoogleFont>
    },
    {
      family: 'PlayfairDisplay',
      load: () => import('@remotion/google-fonts/PlayfairDisplay') as Promise<GoogleFont>
    },
    {
      family: 'Raleway',
      load: () => import('@remotion/google-fonts/Raleway') as Promise<GoogleFont>
    },
    {
      family: 'RobotoMono',
      load: () => import('@remotion/google-fonts/RobotoMono') as Promise<GoogleFont>
    },
    {
      family: 'Ubuntu',
      load: () => import('@remotion/google-fonts/Ubuntu') as Promise<GoogleFont>
    },
    {
      family: 'Pacifico',
      load: () => import('@remotion/google-fonts/Pacifico') as Promise<GoogleFont>
    },
    {
      family: 'Satisfy',
      load: () => import('@remotion/google-fonts/Satisfy') as Promise<GoogleFont>
    },
    {
      family: 'Lobster',
      load: () => import('@remotion/google-fonts/Lobster') as Promise<GoogleFont>
    }
  ],
  [LANGUAGES.HINDI]: [
    { family: 'Default', load: () => undefined },
    {
      family: 'NotoSansDevanagari',
      load: () => import('@remotion/google-fonts/NotoSansDevanagari') as Promise<GoogleFont>
    },
    {
      family: 'Rajdhani',
      load: () => import('@remotion/google-fonts/Rajdhani') as Promise<GoogleFont>
    },
    {
      family: 'YatraOne',
      load: () => import('@remotion/google-fonts/YatraOne') as Promise<GoogleFont>
    },
    {
      family: 'RozhaOne',
      load: () => import('@remotion/google-fonts/RozhaOne') as Promise<GoogleFont>
    },
    {
      family: 'Rajdhani',
      load: () => import('@remotion/google-fonts/Amita') as Promise<GoogleFont>
    },
    {
      family: 'Dekko',
      load: () => import('@remotion/google-fonts/Dekko') as Promise<GoogleFont>
    },
    {
      family: 'Mukta',
      load: () => import('@remotion/google-fonts/Mukta') as Promise<GoogleFont>
    }
  ],
  [LANGUAGES.TELUGU]: [
    { family: 'Default', load: () => undefined },
    {
      family: 'NotoSansTelugu',
      load: () => import('@remotion/google-fonts/NotoSansTelugu') as Promise<GoogleFont>
    },
    {
      family: 'NTR',
      load: () => import('@remotion/google-fonts/NTR') as Promise<GoogleFont>
    },
    {
      family: 'LakkiReddy',
      load: () => import('@remotion/google-fonts/LakkiReddy') as Promise<GoogleFont>
    },
    {
      family: 'Gurajada',
      load: () => import('@remotion/google-fonts/Gurajada') as Promise<GoogleFont>
    },
    {
      family: 'Ramabhadra',
      load: () => import('@remotion/google-fonts/Ramabhadra') as Promise<GoogleFont>
    },
    {
      family: 'SreeKrushnadevaraya',
      load: () => import('@remotion/google-fonts/SreeKrushnadevaraya') as Promise<GoogleFont>
    }
  ],
  [LANGUAGES.KANNADA]: [
    { family: 'Default', load: () => undefined },
    {
      family: 'NotoSansKannada',
      load: () => import('@remotion/google-fonts/NotoSansKannada') as Promise<GoogleFont>
    },
    {
      family: 'BalooTamma2',
      load: () => import('@remotion/google-fonts/BalooTamma2') as Promise<GoogleFont>
    },
    {
      family: 'TiroKannada',
      load: () => import('@remotion/google-fonts/TiroKannada') as Promise<GoogleFont>
    },
    {
      family: 'HindGuntur',
      load: () => import('@remotion/google-fonts/HindGuntur') as Promise<GoogleFont>
    }
  ],
  [LANGUAGES.TAMIL]: [
    { family: 'Default', load: () => undefined },
    {
      family: 'NotoSansTamil',
      load: () => import('@remotion/google-fonts/NotoSansTamil') as Promise<GoogleFont>
    },
    {
      family: 'Catamaran',
      load: () => import('@remotion/google-fonts/Catamaran') as Promise<GoogleFont>
    },
    {
      family: 'MuktaMalar',
      load: () => import('@remotion/google-fonts/MuktaMalar') as Promise<GoogleFont>
    },
    {
      family: 'BalooThambi2',
      load: () => import('@remotion/google-fonts/BalooThambi2') as Promise<GoogleFont>
    },
    {
      family: 'TiroTamil',
      load: () => import('@remotion/google-fonts/TiroTamil') as Promise<GoogleFont>
    },
    {
      family: 'LakkiReddy',
      load: () => import('@remotion/google-fonts/LakkiReddy') as Promise<GoogleFont>
    }
  ],
  [LANGUAGES.MALAYALAM]: [
    { family: 'Default', load: () => undefined },
    {
      family: 'NotoSansMalayalam',
      load: () => import('@remotion/google-fonts/NotoSansMalayalam') as Promise<GoogleFont>
    },
    {
      family: 'BalooChettan2',
      load: () => import('@remotion/google-fonts/BalooChettan2') as Promise<GoogleFont>
    },
    {
      family: 'Thasadith',
      load: () => import('@remotion/google-fonts/Thasadith') as Promise<GoogleFont>
    },
    {
      family: 'Chilanka',
      load: () => import('@remotion/google-fonts/Chilanka') as Promise<GoogleFont>
    },
    {
      family: 'Poppins',
      load: () => import('@remotion/google-fonts/Poppins') as Promise<GoogleFont>
    },
    {
      family: 'LakkiReddy',
      load: () => import('@remotion/google-fonts/LakkiReddy') as Promise<GoogleFont>
    }
  ]
};
