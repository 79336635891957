import { format } from 'date-fns';

const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export class DateService {
  static formatDate(date, formatStr) {
    return format(date, formatStr);
  }

  static getCurrentHTMLDate() {
    return DateService.formatDate(new Date(), 'yyyy-MM-dd');
  }

  static convertMilliToMins(time: number) {
    return (time / 60000).toFixed(2);
  }

  static getCurrentDate() {
    return new Date().toISOString();
  }

  static getFullYear() {
    const date: any = new Date();
    return date.getFullYear();
  }

  static getMonthYear(date: string) {
    const dateObj = new Date(date);
    const month = MONTH_NAMES[dateObj.getMonth()];
    const year = dateObj.getFullYear();
    return `${month} ${year}`;
  }

  static getFormattedDate(date: Date, prefomattedDate: any = false, hideYear: any = false, hideTime: any = true) {
    const day = date.getDate();
    const month = MONTH_NAMES[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    let minutes: any = date.getMinutes();

    if (minutes < 10) {
      // Adding leading zero to minutes
      minutes = `0${minutes}`;
    }

    if (prefomattedDate) {
      // Today at 10:20
      // Yesterday at 10:20
      if (hideTime) {
        return `${prefomattedDate}`;
      }
      return `${prefomattedDate} at ${hours}:${minutes}`;
    }

    if (hideYear) {
      // 10 January at 10:20
      if (hideTime) {
        return `${day} ${month}`;
      }
      return `${day} ${month} at ${hours}:${minutes}`;
    }

    // 10 January 2017 at 10:20
    return `${day} ${month} ${year}`;
  }

  static format(dateParam: any) {
    if (!dateParam) {
      return null;
    }

    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const today: any = new Date();
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const hours = Math.round(minutes / 60);
    const days = Math.round(hours / 24);
    const isThisYear = today.getFullYear() === date.getFullYear();

    if (seconds < 30) {
      return 'just now';
    } else if (seconds < 60) {
      return `${seconds}s ago`;
    } else if (minutes < 60) {
      return `${minutes}m ago`;
    } else if (hours < 24) {
      return `${hours}h ago`;
    } else if (days < 30) {
      return `${days}d ago`;
    } else if (isThisYear) {
      return this.getFormattedDate(date, false, false); // 10 January at 10:20
    }
    return this.getFormattedDate(date); // 10 January 2017 at 10:20
  }
}
