import SubHeader from '@/ui/components/helpers/SubHeader';
import { templates } from '@/ui/services/TemplatesService';
import { Link } from 'react-router-dom';
import { TemplatesItem } from './TemplatesItem';

const Templates = () => {
  return (
    <div className="container mx-auto px-3 sm:px-0">
      <SubHeader title="Templates" />
      <div className="text text-base pb-7">
        Create stunning and personalized video invitations and heartfelt wishes effortlessly with our selection of customizable templates. From
        birthdays to weddings, baby showers to corporate events, our user-friendly platform empowers you to craft visually captivating videos that
        capture the essence of your occasion.
      </div>
      {templates.map((template: any) => {
        if (!template.videos.length) return null;
        return (
          <div key={template.sku}>
            <div className="flex flex-row justify-between items-center mb-3 py-2">
              <h2 className="text-lg">{template.name} Templates</h2>
              <Link to={`/templates/${template.sku}`} className="text-lg text-primary">
                <div>More</div>
              </Link>
            </div>
            <div className="mb-5 leading-relaxed">{template.details}</div>
            <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 grid-cols-1 gap-3 mb-3">
              {template.videos.slice(0, 4).map((video: any) => (
                <TemplatesItem key={video.sku} template={template} video={video} />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Templates;
