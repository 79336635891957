import { useMemo } from 'react';
import festivalMusic from '@/remotion/assets/sounds/independence-day.mp3';
import festivalVideo from '@/remotion/assets/videos/festival-indipendenceday.mp4';
import WishesContent from '@/remotion/helpers/WishesContent';
import { Wraper } from '@/remotion/helpers/Wraper';
import { getTemplateVideo } from '@/ui/services/TemplatesService';
import { templateSkus } from '@/ui/services/TemplateVideosService';
import { z } from 'zod';
import { festivalSchema } from './FestivalsSchema';

const FestivalWishesForIndependenceDay: React.FC<z.infer<typeof festivalSchema>> = props => {
  const { language, color = '#3f45b4', message } = props;
  const template = useMemo(() => getTemplateVideo('festivals', templateSkus.festivalIndependenceDayWishes), []);
  const labels = template?.languages[language].labels;

  return (
    <Wraper color={color} template={template} data={props} video={festivalVideo} playbackRate={0.9} music={festivalMusic}>
      <WishesContent className="w-[850px] flex flex-col justify-end pl-8 pb-16" labels={labels} language={language} message={message} color={color} />
    </Wraper>
  );
};

export default FestivalWishesForIndependenceDay;
