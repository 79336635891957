import { useMemo } from 'react';
import { capitalizeFirstChar } from '@/shared/services/Utils';
import { useAddVideoMutation, useUpdateVideoMutation } from '@/shared/services/VideosService';
import { useUserStore } from '@/shared/stores/UserStore';
import { useVideoStore } from '@/shared/stores/VideoStore';
import { FORM_TYPES, VIDEO_STATUS } from '@/shared/types/types';
import { FontPicker } from '@/ui/components/helpers/FontPicker.tsx';
import PaymentBanner from '@/ui/components/payments/PaymentBanner';
import { differenceInMinutes } from 'date-fns';
import { NAlert, NButton, NButtonGroup, NDivider, NInput, NLink, NRadioGroup, NTextarea, useToast } from 'nayan';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { convertPngToBase64, resizeImage } from '../../services/WebUtils';
import { VideosWrapper } from './VideosWrapper';

const VideosNew = () => {
  const toast = useToast();
  const params: any = useParams();
  const navigate = useNavigate();
  const userUuid = useUserStore(state => state.UUID);
  const template = useVideoStore(state => state.template);
  const video = useVideoStore(state => state.video);
  const data = useVideoStore(state => state.video.data);
  const setDefaultVideoData = useVideoStore(state => state.setDefaultVideoData);
  const setVideoData = useVideoStore(state => state.setVideoData);
  const addVideoMutation: any = useAddVideoMutation();
  const updateVideoMutation: any = useUpdateVideoMutation();
  const currentLocation = typeof location !== 'undefined' ? location?.pathname + location?.search : null;

  const langOptions = useMemo(
    () =>
      template.languages
        ? Object.keys(template.languages).map(l => ({ label: capitalizeFirstChar(l), value: l }))
        : [{ label: 'English', value: 'english' }],
    [template]
  );

  const onSubmit = e => {
    e.preventDefault();
    if (!userUuid) return;
    const video = useVideoStore.getState().video;
    if (!params.uuid) {
      addVideoMutation
        .mutateAsync(video)
        .then(res => {
          toast('Video saved successfully.');
          navigate(`/templates/${video.compositionType}/${video.compositionId}/${res.data.uuid}`, { replace: true });
        })
        .catch(error => toast(error.message));
    } else {
      updateVideoMutation
        .mutateAsync(video)
        .then(() => {
          toast('Video updated successfully.');
        })
        .catch(error => toast(error.message));
    }
  };

  const statusMapping = {
    [VIDEO_STATUS.CREATED]: 'Video is waiting to get processed.',
    [VIDEO_STATUS.FAILED]: 'Video is waiting to get processed.',
    [VIDEO_STATUS.RENDERING]: 'Video is getting generated, hold tight.',
    [VIDEO_STATUS.RENDERED]: 'Video generated successfully. Available for Share & Download.'
  };

  return (
    <VideosWrapper>
      <h1 className="text-lg mb-1">{template.name}</h1>
      <div className="text-sm mb-2">{template.description}</div>
      <ul className="mb-2 list-disc	pl-4">
        <li>Simply fill out form with your information to generate the video.</li>
        <li>Upgrade your account / pay for the video to get watermark removed.</li>
        <li>
          Non-English languages wont support direct typing, instead copy your content. Recommended tool is{' '}
          <NLink href="https://www.google.com/intl/hi/inputtools/try/" target="_blank">
            Google input
          </NLink>{' '}
        </li>
      </ul>
      {!!params.uuid && video.userUuid === userUuid && !video.isPremium && <PaymentBanner videoUuid={params.uuid} />}
      {(!params.uuid || video.userUuid !== userUuid || !!video.isPremium) && <NDivider className="mb-3 mt-2" />}
      {!!params.uuid && differenceInMinutes(new Date(), new Date(video.created)) <= 500 && (
        <NAlert title="" className="mb-3" type="INFO" message={statusMapping[video.status]} />
      )}
      <form onSubmit={onSubmit}>
        <NInput
          key="title"
          type="text"
          label="Video title"
          value={data['title'] || ''}
          required={true}
          onChange={e => setVideoData('title', e.target.value)}
        />
        <NTextarea
          className="mb-3"
          key="description"
          type="text"
          label="Video description"
          value={data['description'] || ''}
          required={true}
          onChange={e => setVideoData('description', e.target.value)}
        />
        <h3 className="text-lg mb-3">Customize video content</h3>
        {!!template?.platforms?.mobile && (
          <div className="flex justify-between items-center mb-3">
            <div className="">Video dimension</div>
            <NButtonGroup
              disabled={!!video.uuid}
              items={template.platforms ? Object.keys(template.platforms) : []}
              selected={data['type'] || 'web'}
              onChange={type => setVideoData('type', type)}
            />
          </div>
        )}
        <div className="flex flex-col justify-start mb-5">
          <div className="mb-2">Choose Language</div>
          <NRadioGroup
            className="flex-wrap gap-3"
            items={langOptions}
            selected={data['language'] || 'english'}
            onChange={language => {
              setDefaultVideoData(template.languages[language].props);
            }}
            disabled={!!video.uuid}
          />
        </div>
        <FontPicker language={video.data.language} selected={video.data.font} onChange={font => setVideoData('font', font)} />
        {template?.form?.map(item => {
          if (item.type === FORM_TYPES.PNG) {
            return (
              <NInput
                key={item.key}
                type="file"
                label={item.label}
                required={true}
                disabled={!!video.uuid}
                accept="image/*"
                onChange={async e => {
                  const file = e.target.files[0];
                  if (file.size > 100 * 1024) {
                    toast('Image size should not exceed 100KB.');
                    return;
                  }
                  const uri = await convertPngToBase64(file);
                  setVideoData(item.key, uri);
                }}
              />
            );
          }
          if (item.type === FORM_TYPES.FILE) {
            return (
              <NInput
                key={item.key}
                type={item.type}
                label={item.label}
                required={true}
                disabled={!!video.uuid}
                onChange={async e => {
                  const file = e.target.files[0];
                  const uri = await resizeImage(file, 150, 150);
                  setVideoData(item.key, uri);
                }}
              />
            );
          }
          if (item.type === FORM_TYPES.TEXTAREA) {
            return (
              <NTextarea
                className="mb-3"
                key={item.key}
                label={item.label}
                value={data[item.key] || ''}
                required={true}
                disabled={!!video.uuid}
                onChange={e => setVideoData(item.key, e.target.value)}
              />
            );
          }
          if (item.type === FORM_TYPES.COLOR) {
            return (
              <div className="flex flex-row justify-between items-center mb-3">
                <label>{item.label}</label>
                <NInput
                  key={item.key}
                  type={item.type}
                  value={data[item.key] || ''}
                  required={true}
                  disabled={!!video.uuid}
                  className="mb-0"
                  inputClassName="p-0 w-[30px] rounded px-0.5 "
                  onChange={e => setVideoData(item.key, e.target.value)}
                />
              </div>
            );
          }
          return (
            <NInput
              key={item.key}
              type={item.type}
              label={item.label}
              value={data[item.key] || ''}
              required={true}
              disabled={!!video.uuid}
              onChange={e => setVideoData(item.key, e.target.value)}
            />
          );
        })}
        {!userUuid && (
          <div className="text-primary mb-3">
            Login is mandatory to save video's.{' '}
            <Link to={`/login?redirect=${currentLocation}`}>
              <span className="underline">Click here to Login.</span>
            </Link>
          </div>
        )}
        <NButton className="mr-3" type="submit" isLoading={addVideoMutation.isLoading || updateVideoMutation.isLoading} disabled={!userUuid}>
          {!!video.uuid ? 'Update' : 'Create'}
        </NButton>
        {!!params.uuid && (
          <Link
            className="px-2 py-3 text-primary border border-primary rounded bg-card hover:text-white hover:bg-primary"
            to={'/videos/' + params.uuid}>
            View video
          </Link>
        )}
      </form>
    </VideosWrapper>
  );
};

export default VideosNew;
