export const QUERY_KEYS = {
  VIDEOS: 'VIDEOS',
  SAVED: 'SAVED',
  COMMENTS: 'COMMENTS',
  NOTIFICATIONS: 'NOTIFICATIONS',
  PAYMENTS: 'PAYMENTS',
  USERS: 'USERS'
};

export const formatQueryData = (data: any) => {
  return data?.data ? (Array.isArray(data?.data) ? [...data.data] : { ...data.data }) : undefined;
};

export const formatInfiniteQueryData = (data: any) => {
  return (data && (data.pages?.map((g: any) => (g.data && g.data.map((data: any) => data || [])) || []) || []).flat()) || [];
};

export const getNextPageParam = (lastPage: any, pages: any) => {
  if (lastPage?.data?.length === 20) {
    return pages.length + 1;
  }
  return undefined;
};

export const onErrorData = (queryClient: any, queryKey: any, context: any) => {
  queryClient.setQueryData(queryKey, context?.oldData);
};
