import { cn } from 'nayan';
import { useCurrentFrame } from 'remotion';
import { fadeInEffect } from '../services/utils';

interface Props {
  from: number;
  to: number;
  children: any;
  color?: string;
  fontFamily?: string;
  className?: string;
  style?: any;
}

export const Text = (props: Props) => {
  const { from, children, to, className = '', fontFamily = 'inherit', color = '#000000', style = {} } = props;
  const frame = useCurrentFrame();
  const opacity = fadeInEffect(frame, from, to);

  return (
    <div
      style={{ color, opacity, fontFamily, textShadow: '0 0 20px rgba(0, 0, 0, 0.5)', ...style }}
      className={cn(`text-white leading-relaxed ${className}`)}>
      {children}
    </div>
  );
};
