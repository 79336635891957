import brideImg from '@/remotion/assets/images/bride.jpg';
import ganeshaImg from '@/remotion/assets/images/ganesha.png';
import groomImg from '@/remotion/assets/images/groom.jpg';
import venueImg from '@/remotion/assets/images/venue.png';
import weddingImg from '@/remotion/assets/images/wedding.png';
import { Center } from '@/remotion/helpers/Center';
import { Image } from '@/remotion/helpers/Image';
import { Text } from '@/remotion/helpers/Text';
import { LANGUAGES } from '@/ui/shared/config/constants';

export const MarriageWelcome = ({ image, props, color, className = '' }: any): any => {
  return (
    <Center className={className}>
      <Image source={image || ganeshaImg} from={0} to={20} className="w-[200px] h-[200px]" />
      <Text className="text-[30px] mt-5" color={color} from={10} to={30}>
        || {props.welcomeMsg} ||
      </Text>
    </Center>
  );
};

export const MarriageTitle = ({ props, color, className = '' }: any) => {
  return (
    <Center className={className}>
      <Text className="text-[35px] uppercase mb-3" color={color} from={0} to={20}>
        {props.name}
      </Text>
    </Center>
  );
};

export const MarriageMessage = ({ color, props, className = '' }: any) => {
  return (
    <Center className={className}>
      <Text className="text-[30px] max-w-[700px] text-center uppercase leading-relaxed" color={color} from={0} to={20}>
        {props.message}
      </Text>
    </Center>
  );
};

export const MarriageGroomDetails = ({ props, labels, color, className = '' }) => {
  return (
    <Center className={className}>
      <Image
        source={props.groomImage || groomImg}
        from={0}
        to={20}
        className="w-[180px] h-[180px] border-[5px] rounded-full mb-5"
        style={{ borderColor: color }}
      />
      <Text className="text-[35px] mb-5" color={color} from={10} to={30}>
        {props.groomName}
      </Text>
      {props.language === LANGUAGES.ENGLISH && (
        <Text className="text-[25px] text-center" color={color} from={20} to={40}>
          {labels.sonOf}
        </Text>
      )}
      <Text className="text-[30px] text-center" color={color} from={20} to={40}>
        {props.groomParents}
      </Text>
      {props.language !== LANGUAGES.ENGLISH && (
        <Text className="text-[25px] text-center mt-3" color={color} from={20} to={40}>
          {labels.sonOf}
        </Text>
      )}
    </Center>
  );
};

export const MarriageBrideDetails = ({ props, labels, color, className = '' }) => {
  return (
    <Center className={className}>
      <Image
        source={props.brideImage || brideImg}
        from={0}
        to={20}
        className="w-[180px] h-[180px] border-[5px] rounded-full mb-5"
        style={{ borderColor: color }}
      />
      <Text className="text-[35px] mb-5" color={color} from={10} to={30}>
        {props.brideName}
      </Text>
      {props.language === LANGUAGES.ENGLISH && (
        <Text className="text-[25px] text-center" color={color} from={20} to={40}>
          {labels.daughterOf}
        </Text>
      )}
      <Text className="text-[30px] text-center" color={color} from={20} to={40}>
        {props.brideParents}
      </Text>
      {props.language !== LANGUAGES.ENGLISH && (
        <Text className="text-[25px] text-center mt-3" color={color} from={20} to={40}>
          {labels.daughterOf}
        </Text>
      )}
    </Center>
  );
};

export const MarriageTiming = ({ props, labels, color, className = '' }) => {
  return (
    <Center className={className}>
      <Image source={weddingImg} from={0} to={20} className="w-[200px] h-[200px] mb-5" />
      <Text className="text-[25px] mb-3" color={color} from={10} to={30}>
        {labels.weddingOn}
      </Text>
      <Text className="text-[30px] max-w-[700px] text-center" color={color} from={10} to={30}>
        {props.date}
      </Text>
    </Center>
  );
};

export const MarriageVenue = ({ props, labels, color, className = '' }) => {
  return (
    <Center className={className}>
      <Image source={venueImg} from={0} to={20} className="w-[200px] h-[200px] mb-5" />
      <Text className="text-[25px] mb-3" color={color} from={10} to={30}>
        {labels.venue}
      </Text>
      <Text className="text-[30px] max-w-[700px] text-center leading-relaxed" color={color} from={10} to={30}>
        {props.venue}
      </Text>
    </Center>
  );
};
