import { useMemo } from 'react';
import festivalMusic from '@/remotion/assets/sounds/christmas.mp3';
import festivalVideo from '@/remotion/assets/videos/festival-christmas.mp4';
import WishesContent from '@/remotion/helpers/WishesContent';
import { Wraper } from '@/remotion/helpers/Wraper';
import { getTemplateVideo } from '@/ui/services/TemplatesService';
import { templateSkus } from '@/ui/services/TemplateVideosService';
import { z } from 'zod';
import { festivalSchema } from './FestivalsSchema';

const FestivalWishesForChristmas: React.FC<z.infer<typeof festivalSchema>> = props => {
  const { language, color = '#a2d569', message } = props;
  const template = useMemo(() => getTemplateVideo('festivals', templateSkus.festivalChristmasWishes), []);
  const labels = template?.languages[language].labels;

  return (
    <Wraper color={color} template={template} data={props} video={festivalVideo} music={festivalMusic}>
      <WishesContent
        className="w-[850px] flex flex-col justify-center mx-auto pt-20"
        labels={labels}
        language={language}
        message={message}
        color={color}
      />
    </Wraper>
  );
};

export default FestivalWishesForChristmas;
