import ganeshaImg from '@/remotion/assets/images/ganesha.png';
import houseImg from '@/remotion/assets/images/house.jpg';
import { Center } from '@/remotion/helpers/Center';
import { Image } from '@/remotion/helpers/Image';
import { Text } from '@/remotion/helpers/Text';

export const HouseWarmingWelcome = ({ image, props, color, className = '' }: any): any => {
  return (
    <Center className={className}>
      <Image source={image || ganeshaImg} from={0} to={20} className="w-[200px] h-[200px]" />
      <Text className="text-[30px] mt-5" color={color} from={10} to={30}>
        || {props.welcomeMsg} ||
      </Text>
    </Center>
  );
};

export const HouseWarmingTitle = ({ props, color, className = '' }: any) => {
  return (
    <Center className={className}>
      <Text className="text-[50px] mt-5 max-w-[700px] text-center" color={color} from={10} to={20}>
        {props.name}
      </Text>
    </Center>
  );
};

export const HouseWarmingPhoto = ({ props, color, className = '' }: any) => {
  return (
    <Center className={className}>
      <Image
        source={props.image || houseImg}
        from={0}
        to={30}
        className="w-[180px] h-[180px] border-[5px] rounded-full mb-5"
        style={{ borderColor: color }}
      />
      <Text className="text-[35px] max-w-[700px] text-center leading-relaxed line-clamp-4" color={color} from={20} to={50}>
        {props.message}
      </Text>
    </Center>
  );
};

export const HouseWarmingDetails = ({ labels, props, color, className = '' }) => {
  return (
    <Center className={className}>
      <Text className="text-[25px] mb-3" color={color} from={0} to={20}>
        {labels.date}
      </Text>
      <Text className="text-[30px] max-w-[700px] text-center" color={color} from={0} to={20}>
        {props.date}
      </Text>
      <div className="h-16"></div>
      <Text className="text-[25px] mb-3" color={color} from={10} to={30}>
        {labels.venue}
      </Text>
      <Text className="text-[30px] max-w-[700px] text-center leading-relaxed" color={color} from={10} to={30}>
        {props.venue}
      </Text>
    </Center>
  );
};

export const HouseWarmingInviters = ({ labels, props, color, className = '' }) => {
  return (
    <Center className={className}>
      <Text className="text-[25px] max-w-[700px] text-center mb-3" color={color} from={0} to={20}>
        {labels.invitedBy}
      </Text>
      <Text className="text-[30px] max-w-[700px] text-center leading-relaxed" color={color} from={0} to={20}>
        {props.invitedBy}
      </Text>
    </Center>
  );
};
