import { Suspense } from 'react';
import { Meta } from '@/ui/components/helpers/Meta';
import { NLoading } from 'nayan';
import Banner from './Banner';
import Features from './Features';
import Pricing from './Pricing';
import Templates from './Templates';

const Home = () => {
  return (
    <Suspense fallback={<NLoading />}>
      <div className="-mt-3 md:-mt-3">
        <Meta />
        <Banner />
        <Features />
        <Templates />
        <Pricing />
      </div>
    </Suspense>
  );
};

export default Home;
