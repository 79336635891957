import { v4 as uuidv4 } from 'uuid';
import { useUserStore } from '../stores/UserStore';
import { RestService } from './RestService';

export const isLogin = () => !!useUserStore.getState().UUID;

export const loginUser = async (data: any) => {
  return RestService.post('/auth/login', data).then((user: any) => {
    useUserStore.setState({
      UUID: user.data.uuid,
      UNAME: user.data.name,
      UEMAIL: user.data.email,
      UMOBILE: user.data.mobile,
      UABOUT: user.data.about,
      UDOB: user.data.dob,
      TOKEN: user.data.token
    });
    return user.data;
  });
};

export const socialLogin = async (data: any) => {
  return RestService.post('/auth/social', data).then((user: any) => {
    useUserStore.setState({
      UUID: user.data.uuid,
      UNAME: user.data.name,
      UEMAIL: user.data.email,
      UMOBILE: user.data.mobile,
      UABOUT: user.data.about,
      UDOB: user.data.dob,
      TOKEN: user.data.token
    });
    return user.data;
  });
};

export const forgotPassword = (data: any) => {
  return RestService.post('/auth/forgot-password', data);
};

export const resetPassword = (data: any) => {
  return RestService.post('/auth/reset-password', data);
};

export const changePassword = (data: any) => {
  return RestService.post('/auth/change-password', data);
};

export const signupUser = (data: any) => {
  const uuid = uuidv4();
  const user = Object.assign(data, { uuid });
  return RestService.post('/auth/signup', user);
};

export const updatePushToken = async (pushToken: string) => {
  const userUuid = useUserStore.getState().UUID;
  const push = { userUuid, pushToken };
  return RestService.post('/push', push);
};

export const deleteAccount = (data: any) => {
  return RestService.post('/auth/delete-account', data);
};

export const verifyAccount = (data: any) => {
  return RestService.post('/auth/verify', data);
};
