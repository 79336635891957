import { LANGUAGES, VIDEO_TYPES } from '@/shared/config/constants';
import { FORM_TYPES } from '@/shared/types/types';
import { z } from 'zod';

export const lifeEventsTags = [
  // Work Anniversary
  { sku: 'work-anniversary-video-wishes', text: 'Work Anniversary Video Wishes' },
  { sku: 'create-work-anniversary-video-messages', text: 'Create Work Anniversary Video Messages' },
  { sku: 'happy-work-anniversary-video-greetings', text: 'Happy Work Anniversary Video Greetings' },
  { sku: 'custom-work-anniversary-video-wishes', text: 'Custom Work Anniversary Video Wishes' },
  { sku: 'professional-work-anniversary-video-cards', text: 'Professional Work Anniversary Video Cards' },
  { sku: 'personalized-work-anniversary-video-messages', text: 'Personalized Work Anniversary Video Messages' },
  { sku: 'employee-work-anniversary-video-ideas', text: 'Employee Work Anniversary Video Ideas' },
  { sku: 'work-anniversary-video-tribute', text: 'Work Anniversary Video Tribute' },
  { sku: 'virtual-work-anniversary-celebration-video', text: 'Virtual Work Anniversary Celebration Video' },
  { sku: 'corporate-work-anniversary-video-greetings', text: 'Corporate Work Anniversary Video Greetings' },

  // Marriage Anniversary
  { sku: 'marriage-anniversary-video-wishes', text: 'Marriage Anniversary Video Wishes' },
  { sku: 'create-wedding-anniversary-video-greetings', text: 'Create Wedding Anniversary Video Greetings' },
  { sku: 'romantic-anniversary-video-messages', text: 'Romantic Anniversary Video Messages' },
  { sku: 'personalized-anniversary-video-for-couples', text: 'Personalized Anniversary Video For Couples' },
  { sku: 'happy-marriage-anniversary-video-cards', text: 'Happy Marriage Anniversary Video Cards' },
  { sku: 'custom-wedding-anniversary-video', text: 'Custom Wedding Anniversary Video' },
  { sku: '1st-marriage-anniversary-video-messages', text: '1st Marriage Anniversary Video Messages' },
  { sku: 'milestone-anniversary-video-tribute', text: 'Milestone Anniversary Video Tribute' },
  { sku: 'anniversary-video-montage-for-couples', text: 'Anniversary Video Montage For Couples' },
  { sku: 'video-wishes-for-50th-wedding-anniversary', text: 'Video Wishes For 50th Wedding Anniversary' },

  // Retirement
  { sku: 'retirement-video-wishes', text: 'Retirement Video Wishes' },
  { sku: 'congratulations-on-retirement-video-messages', text: 'Congratulations On Retirement Video Messages' },
  { sku: 'retirement-video-tribute-ideas', text: 'Retirement Video Tribute Ideas' },
  { sku: 'personalized-retirement-video-greetings', text: 'Personalized Retirement Video Greetings' },
  { sku: 'happy-retirement-video-card', text: 'Happy Retirement Video Card' },
  { sku: 'custom-retirement-celebration-video', text: 'Custom Retirement Celebration Video' },
  { sku: 'retirement-video-montage-ideas', text: 'Retirement Video Montage Ideas' },
  { sku: 'retirement-party-video-invitations', text: 'Retirement Party Video Invitations' },
  { sku: 'farewell-video-messages-for-retirement', text: 'Farewell Video Messages For Retirement' },
  { sku: 'create-virtual-retirement-celebration-video', text: 'Create Virtual Retirement Celebration Video' },

  // Death Anniversary
  { sku: 'death-anniversary-remembrance-video', text: 'Death Anniversary Remembrance Video' },
  { sku: 'create-death-anniversary-video-tribute', text: 'Create Death Anniversary Video Tribute' },
  { sku: 'video-messages-for-death-anniversary', text: 'Video Messages For Death Anniversary' },
  { sku: 'in-memory-of-loved-one-video-messages', text: 'In Memory Of Loved One Video Messages' },
  { sku: 'death-anniversary-memorial-video', text: 'Death Anniversary Memorial Video' },
  { sku: 'personalized-remembrance-video-tribute', text: 'Personalized Remembrance Video Tribute' },
  { sku: 'death-anniversary-video-tribute-ideas', text: 'Death Anniversary Video Tribute Ideas' },
  { sku: 'video-memorial-for-loved-ones', text: 'Video Memorial For Loved Ones' },
  { sku: 'video-wishes-for-death-anniversary', text: 'Video Wishes For Death Anniversary' },
  { sku: 'commemorating-death-anniversary-video', text: 'Commemorating Death Anniversary Video' },

  // Birthdays
  { sku: 'happy-birthday-video-wishes', text: 'Happy Birthday Video Wishes' },
  { sku: 'create-personalized-birthday-video-messages', text: 'Create Personalized Birthday Video Messages' },
  { sku: 'birthday-video-greetings-for-friends', text: 'Birthday Video Greetings For Friends' },
  { sku: 'custom-birthday-video-cards', text: 'Custom Birthday Video Cards' },
  { sku: 'fun-birthday-video-messages', text: 'Fun Birthday Video Messages' },
  { sku: 'birthday-party-invitation-video', text: 'Birthday Party Invitation Video' },
  { sku: 'milestone-birthday-video-greetings', text: 'Milestone Birthday Video Greetings' },
  { sku: 'video-montage-for-birthdays', text: 'Video Montage For Birthdays' },
  { sku: 'special-birthday-video-wishes', text: 'Special Birthday Video Wishes' },
  { sku: 'birthday-video-surprise-ideas', text: 'Birthday Video Surprise Ideas' },

  // Graduation
  { sku: 'graduation-video-wishes', text: 'Graduation Video Wishes' },
  { sku: 'congratulations-graduation-video-messages', text: 'Congratulations Graduation Video Messages' },
  { sku: 'graduation-video-greeting-card', text: 'Graduation Video Greeting Card' },
  { sku: 'personalized-graduation-video-tribute', text: 'Personalized Graduation Video Tribute' },
  { sku: 'create-graduation-video-montage', text: 'Create Graduation Video Montage' },
  { sku: 'virtual-graduation-party-video', text: 'Virtual Graduation Party Video' },
  { sku: 'custom-graduation-video-messages', text: 'Custom Graduation Video Messages' },
  { sku: 'graduation-ceremony-video-invitations', text: 'Graduation Ceremony Video Invitations' },
  { sku: 'graduation-video-speeches', text: 'Graduation Video Speeches' },
  { sku: 'graduation-video-wishes-for-students', text: 'Graduation Video Wishes For Students' },

  // New Job
  { sku: 'new-job-video-wishes', text: 'New Job Video Wishes' },
  { sku: 'congratulations-on-new-job-video-messages', text: 'Congratulations On New Job Video Messages' },
  { sku: 'create-video-greetings-for-new-job', text: 'Create Video Greetings For New Job' },
  { sku: 'personalized-new-job-video-card', text: 'Personalized New Job Video Card' },
  { sku: 'best-wishes-video-for-new-career', text: 'Best Wishes Video For New Career' },
  { sku: 'virtual-celebration-video-for-new-job', text: 'Virtual Celebration Video For New Job' },
  { sku: 'new-job-video-tribute-ideas', text: 'New Job Video Tribute Ideas' },
  { sku: 'promotion-congratulations-video-messages', text: 'Promotion Congratulations Video Messages' },
  { sku: 'new-job-video-greeting-card', text: 'New Job Video Greeting Card' },
  { sku: 'first-day-at-new-job-video-message', text: 'First Day At New Job Video Message' }
];

export const lifeEventsSchema = z.object({
  type: z.string(),
  language: z.nativeEnum(LANGUAGES),
  color: z.string().optional(),
  font: z.string(),
  name: z.string(),
  message: z.string(),
  image: z.string()
});

export const lifeEventsWishesForm = [
  { type: FORM_TYPES.COLOR, key: 'color', label: 'Choose text color' },
  { type: FORM_TYPES.TEXTAREA, key: 'message', label: 'Enter message' },
  { type: FORM_TYPES.FILE, key: 'image', label: 'Select image' }
];

export const anniversaryWishesLanguages = {
  [LANGUAGES.ENGLISH]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.ENGLISH,
      title: 'Anniversary Wishes',
      description: 'Create anniversary wishes and share with family and friends.',
      message:
        'Your dedication and commitment inspire everyone around you. Wishing you many more successful years ahead. Happy 10th Work Anniversary Manish Agarwal',
      image: ''
    }
  },
  [LANGUAGES.HINDI]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.HINDI,
      title: 'Anniversary Wishes',
      description: 'Create anniversary wishes and share with family and friends.',
      message:
        'आपका समर्पण और प्रतिबद्धता आपके आस-पास के सभी लोगों को प्रेरित करती है। आपको आने वाले कई सफल वर्षों की शुभकामनाएं। मनीष अग्रवाल को दसवीं कार्य वर्षगाँठ की शुभकामनाएँ',
      image: ''
    }
  },
  [LANGUAGES.TELUGU]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TELUGU,
      title: 'Anniversary Wishes',
      description: 'Create anniversary wishes and share with family and friends.',
      message:
        'మీ కట్టుబాటు మరియు నిబద్ధత అందరికీ స్ఫూర్తినిస్తుంది. మీకు మరెన్నో విజయవంతమైన సంవత్సరాలు రావాలని కోరుకుంటున్నాను. 10వ వార్షికోత్సవ శుభాకాంక్షలు మనీష్ అగర్వాల్.',
      image: ''
    }
  },
  [LANGUAGES.KANNADA]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.KANNADA,
      title: 'Anniversary Wishes',
      description: 'Create anniversary wishes and share with family and friends.',
      message:
        'ನಿಮ್ಮ ಸಮರ್ಪಣೆ ಮತ್ತು ಬದ್ಧತೆಯು ನಿಮ್ಮ ಸುತ್ತಲಿರುವ ಎಲ್ಲರಿಗೂ ಸ್ಫೂರ್ತಿ ನೀಡುತ್ತದೆ. ನಿಮಗೆ ಇನ್ನೂ ಹಲವು ಯಶಸ್ವಿ ವರ್ಷಗಳು ಬರಲಿ ಎಂದು ಹಾರೈಸುತ್ತೇನೆ. ಮನೀಶ್ ಅಗರ್ವಾಲ್ ಅವರ 10 ನೇ ಕೆಲಸದ ವಾರ್ಷಿಕೋತ್ಸವದ ಶುಭಾಶಯಗಳು',
      image: ''
    }
  },
  [LANGUAGES.TAMIL]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.TAMIL,
      title: 'Anniversary Wishes',
      description: 'Create anniversary wishes and share with family and friends.',
      message:
        'உங்கள் அர்ப்பணிப்பு மற்றும் பொறுப்புணர்வு உங்களைச் சுற்றியுள்ள அனைவரையும் ஊக்குவிக்கின்றன. உங்களுக்கு இன்னும் நிறைய வெற்றிகரமான ஆண்டுகள் வாழ்த்துகிறேன். 10வது வேலை ஆண்டுவிழா வாழ்த்துகள் மணீஷ் அகர்வால்.',
      image: ''
    }
  },
  [LANGUAGES.MALAYALAM]: {
    labels: {},
    props: {
      type: VIDEO_TYPES.WEB,
      language: LANGUAGES.MALAYALAM,
      title: 'Anniversary Wishes',
      description: 'Create anniversary wishes and share with family and friends.',
      message:
        'നിങ്ങളുടെ സമർപ്പണവും പ്രതിബദ്ധതയും നിങ്ങളുടെ ചുറ്റുമുള്ള എല്ലാവരെയും പ്രചോദിപ്പിക്കുന്നു. ഒരുപാട് വിജയകരമായ വർഷങ്ങൾ നിങ്ങൾക്ക് മുന്നിൽ ഉണ്ടാവട്ടെ. 10-ാം വര്‍ഷ പ്രഫഷണൽ വാർഷികാശംസകൾ മനീഷ് അഗർവാൾ.',
      image: ''
    }
  }
};
