import { Link } from 'react-router-dom';

const AcceptTerms = () => {
  return (
    <span className="text-text leading-5">
      By clicking on this you have accepted{' '}
      <Link className="text-primary no-underline" to="/terms-of-use">
        Terms of Use
      </Link>
      &nbsp; and &nbsp;
      <Link className="text-primary no-underline" to="/privacy-policies">
        Privacy Policy
      </Link>
    </span>
  );
};

export default AcceptTerms;
