import { useMemo } from 'react';
import festivalMusic from '@/remotion/assets/sounds/raksha-bandhan.mp3';
import festivalVideo from '@/remotion/assets/videos/festival-raksha-bandhan.mp4';
import WishesContent from '@/remotion/helpers/WishesContent';
import { Wraper } from '@/remotion/helpers/Wraper';
import { getTemplateVideo } from '@/ui/services/TemplatesService';
import { templateSkus } from '@/ui/services/TemplateVideosService';
import { z } from 'zod';
import { festivalSchema } from './FestivalsSchema';

const FestivalWishesForRakshaBandan: React.FC<z.infer<typeof festivalSchema>> = props => {
  const { language, color = '#f8c933', message } = props;
  const template = useMemo(() => getTemplateVideo('festivals', templateSkus.festivalRakshaBandanWishes), []);
  const labels = template?.languages[language].labels;

  return (
    <Wraper color={color} template={template} data={props} video={festivalVideo} music={festivalMusic}>
      <WishesContent className="w-[850px] flex flex-col justify-end pl-8 pb-16" labels={labels} language={language} message={message} color={color} />
    </Wraper>
  );
};

export default FestivalWishesForRakshaBandan;
