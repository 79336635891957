import SubHeader from '@/ui/components/helpers/SubHeader';
import { NLink } from 'nayan';

const TermsOfUse = () => {
  return (
    <div className="container mx-auto px-3 sm:px-0">
      <SubHeader title="Terms Of Use" />
      <div className="mb-5">
        Welcome to Inyter, the video invitation and wishes creating application! These Terms of Use govern your access to and use of the Inyter
        application and services. By accessing or using Inyter, you agree to comply with these terms.
      </div>
      <ol>
        <li className="mb-5">
          <strong>1. Acceptance of Terms:</strong> By accessing or using Inyter, you agree to be bound by these Terms of Use, our Privacy Policy, and
          any additional terms and conditions that may apply to specific features or services.
        </li>
        <li className="mb-5">
          <strong>2. Use of Services:</strong> You may use Inyter to create and send video invitations and wishes for personal or non-commercial
          purposes only. You agree not to use Inyter for any unlawful, harmful, or fraudulent purposes.
        </li>
        <li className="mb-5">
          <strong>3. User Accounts:</strong> In order to use certain features of Inyter, you may be required to create a user account. You are
          responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
        </li>
        <li className="mb-5">
          <strong>4. User Content:</strong> By uploading or creating content on Inyter, you grant us a worldwide, non-exclusive, royalty-free license
          to use, reproduce, modify, adapt, publish, translate, distribute, and display such content in connection with the operation of Inyter and
          our business.
        </li>
        <li className="mb-5">
          <strong>5. Prohibited Content:</strong> You agree not to upload, create, or distribute any content that is illegal, defamatory, obscene,
          infringing, or otherwise objectionable. We reserve the right to remove any content that violates these terms or is deemed inappropriate.
        </li>
        <li className="mb-5">
          <strong>6. Intellectual Property:</strong> All intellectual property rights in Inyter and its content are owned by or licensed to us. You
          agree not to use, modify, reproduce, or distribute any of our intellectual property without our prior written consent.
        </li>
        <li className="mb-5">
          <strong>7. Third-Party Links:</strong> Inyter may contain links to third-party websites or services that are not owned or controlled by us.
          We are not responsible for the content or privacy practices of such websites or services.
        </li>
        <li className="mb-5">
          <strong>8. Disclaimer of Warranties:</strong> Inyter is provided on an "as is" and "as available" basis, without any warranties of any kind,
          either express or implied. We do not warrant that Inyter will be error-free or uninterrupted, or that any defects will be corrected.
        </li>
        <li className="mb-5">
          <strong>9. Limitation of Liability:</strong> To the fullest extent permitted by law, we shall not be liable for any indirect, incidental,
          special, consequential, or punitive damages arising out of or in connection with your use of Inyter.
        </li>
        <li className="mb-5">
          <strong>10. Indemnification:</strong> You agree to indemnify and hold us harmless from any claims, damages, losses, or liabilities arising
          out of your use of Inyter or violation of these terms.
        </li>
        <li className="mb-5">
          <strong>11. Governing Law:</strong> These Terms of Use shall be governed by and construed in accordance with the laws of India. Any disputes
          arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of India.
        </li>
        <li className="mb-5">
          <strong>12. Changes to Terms:</strong> We reserve the right to modify or revise these Terms of Use at any time, and such changes will be
          effective immediately upon posting. You are responsible for regularly reviewing these terms to ensure your continued compliance.
        </li>
      </ol>
      <div className="mb-5">
        If you have any questions or concerns about these Terms of Use, please contact us at{' '}
        <NLink href="mailto:hello@inyter.com" target="_blank">
          hello@inyter.com
        </NLink>
        . Thank you for using Inyter!
      </div>
    </div>
  );
};

export default TermsOfUse;
