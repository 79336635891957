import { PlusCircle } from 'lucide-react';
import { Meta } from './Meta';

interface Props {
  title: string;
  buttonText?: string;
  buttonIcon?: any;
  clickButton?: () => void;
  children?: any;
  hasSeo?: boolean;
}

const SubHeader = (props: Props) => {
  const Icon = props.buttonIcon || PlusCircle;

  return (
    <>
      <Meta title={props.title} />
      <div className="flex justify-between items-center rounded max-w-full overflow-hidden mb-3">
        <div className="flex flex-row items-center w-full overflow-hidden">
          <h1 className="text-text text-xl mr-3 whitespace-nowrap">{props.title}</h1>
          <div className="flex flex-row w-full">{props.children}</div>
        </div>
        {!!props.clickButton && (
          <span className="cursor-pointer flex items-center" onClick={props.clickButton}>
            <Icon className="w-4 h-4 text-primary" />
            <span className="pl-1 text-primary">{props.buttonText || 'NEW'}</span>
          </span>
        )}
      </div>
    </>
  );
};

export default SubHeader;
