import { templates } from '@/ui/services/TemplatesService';
import { Link } from 'react-router-dom';

const Templates = () => {
  return (
    <section className="bg-card flex flex-col justify-center text">
      <div className="container mx-auto px-3 sm:px-0">
        <h2 className="text-3xl text text-center mt-12 mb-5">Templates</h2>
        <div className="text text-base mb-12 text-center max-w-[900px] mx-auto">
          Create stunning and personalized video invitations and heartfelt wishes effortlessly with our selection of customizable templates. From
          birthdays to weddings, baby showers to corporate events, our user-friendly platform empowers you to craft visually captivating videos that
          capture the essence of your occasion.
        </div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 grid-cols-1 gap-3 mb-16">
          {templates.map(template => (
            <Link to={`/templates/${template.sku}`} key={template.sku}>
              <div className="col-span-1 flex flex-col justify-center items-center">
                <img
                  width={640}
                  height={360}
                  src={`/images/${template.sku}.jpg`}
                  alt={template.name + ' templates'}
                  className="w-full h-auto rounded blur-[1px]"
                  loading="lazy"
                />
                <div className="text-xl absolute text-white font-bold drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,1)]">{template.name}</div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Templates;
