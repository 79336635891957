import { instance } from '../config/axios';

export class RestService {
  static setToken(token: string) {
    instance.defaults.headers['Authorization'] = !!token ? 'Bearer ' + token : '';
  }

  static get(url: string) {
    return instance.get(url);
  }

  static post(url: string, data: any, options = {}) {
    return instance.post(url, data, options);
  }

  static update(url: string, data: any, options = {}) {
    return instance.put(url, data, options);
  }

  static delete(url: string) {
    return instance.delete(url);
  }
}
