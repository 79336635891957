import { useState } from 'react';
import { resetPassword } from '@/shared/services/AuthService';
import { NButton, NInput, useToast } from 'nayan';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

const Reset = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [npass, setNpass] = useState('');
  const [rpass, setRpass] = useState('');
  const { userUuid, resetUuid }: any = useParams();
  const toast = useToast();

  const onSubmit = e => {
    e.preventDefault();
    if (npass === rpass && npass.length > 3) {
      const data = { userUuid, resetUuid, new_password: npass };
      setIsLoading(true);
      resetPassword(data)
        .then((res: any) => {
          toast(res.data.message);
          navigate('/login', { replace: true });
        })
        .catch((err: any) => toast(err.message))
        .finally(() => setIsLoading(false));
    } else {
      toast('Password not valid, please enter correct password.');
    }
  };

  return (
    <div className="flex justify-center">
      <div className="w-full md:max-w-[450px] mx-auto py-5 md:py-10">
        <form onSubmit={onSubmit}>
          <NInput className="mb-3 card-input" label="New Password" type="password" onChange={e => setNpass(e.target.value)} required={true} />
          <NInput className="mb-3 card-input" label="Re-Type Password" type="password" onChange={e => setRpass(e.target.value)} required={true} />
          <NButton type="submit" className="w-full mb-3" isLoading={isLoading}>
            RESET PASSWORD
          </NButton>
        </form>

        <div className="text-center text">
          Already have account?{' '}
          <span className="text-primary cursor-pointer" onClick={() => navigate('/login', { replace: true })}>
            Login
          </span>
        </div>
      </div>
    </div>
  );
};

export default Reset;
