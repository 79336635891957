import { useMutation, useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { QUERY_KEYS } from '../hooks/hooksUtils';
import { useInfiniteQueryData } from '../hooks/useInfiniteQueryData';
import { useQueryData } from '../hooks/useQueryData';
import { useUserStore } from '../stores/UserStore';
import { useVideoStore } from '../stores/VideoStore';
import { RestService } from './RestService';

export const fetchVideos = ({ pageParam }) => {
  return RestService.get(`/videos?page=${pageParam}`);
};

export const useVideosData = () => {
  return useInfiniteQueryData([QUERY_KEYS.VIDEOS], fetchVideos);
};

export const fetchVideo = ({ queryKey }: any) => {
  return RestService.get(`/videos/` + queryKey[1]);
};

export const useVideoData = (uuid: string) => {
  return useQueryData([QUERY_KEYS.VIDEOS, uuid], fetchVideo, { enabled: !!uuid });
};

export const useAddVideoMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (video: any) => {
      const userUuid = useUserStore.getState().UUID;
      const data = {
        ...video,
        userUuid,
        uuid: uuidv4()
      };
      return RestService.post('/videos', data);
    },
    onSuccess: async (newData: any) => {
      const queryKey = [QUERY_KEYS.VIDEOS];
      await queryClient.cancelQueries({ queryKey });
      const oldData = queryClient.getQueryData(queryKey);
      if (!!oldData) {
        queryClient.setQueryData(queryKey, (oldQueryData: any) => {
          return {
            ...oldQueryData,
            pages: oldQueryData.pages.map((pageData: any, index: number) => {
              return {
                ...pageData,
                data: index === 0 ? [newData.data, ...pageData.data] : pageData.data
              };
            })
          };
        });
      }
      const queryKey2 = [QUERY_KEYS.VIDEOS, newData.data.uuid];
      queryClient.setQueryData(queryKey2, (oldQueryData: any) => {
        return {
          ...oldQueryData,
          data: newData.data
        };
      });
    }
  });
};

export const useUpdateVideoMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => {
      return RestService.update('/videos/' + data.uuid, data);
    },
    onSuccess: async (newData: any) => {
      const queryKey = [QUERY_KEYS.VIDEOS];
      await queryClient.cancelQueries({ queryKey });
      const oldData = queryClient.getQueryData(queryKey);
      if (!!oldData) {
        queryClient.setQueryData(queryKey, (oldQueryData: any) => {
          return {
            ...oldQueryData,
            pages: oldQueryData.pages.map((pageData: any) => {
              return {
                ...pageData,
                data: pageData.data.map(item => {
                  if (item.uuid === newData.data.uuid) {
                    return newData.data;
                  }
                  return item;
                })
              };
            })
          };
        });
      }
      const queryKey1 = [QUERY_KEYS.SAVED];
      await queryClient.cancelQueries({ queryKey: queryKey1 });
      const oldData1 = queryClient.getQueryData(queryKey1);
      if (!!oldData1) {
        queryClient.setQueryData(queryKey1, (oldQueryData: any) => {
          return {
            ...oldQueryData,
            pages: oldQueryData.pages.map((pageData: any) => {
              return {
                ...pageData,
                data: pageData.data.map(item => {
                  if (item.uuid === newData.data.uuid) {
                    return newData.data;
                  }
                  return item;
                })
              };
            })
          };
        });
      }
      const queryKey2 = [QUERY_KEYS.VIDEOS, newData.data.uuid];
      await queryClient.cancelQueries({ queryKey: queryKey2 });
      const oldData2 = queryClient.getQueryData(queryKey2);
      if (!!oldData2) {
        queryClient.setQueryData(queryKey2, (oldQueryData: any) => {
          return {
            ...oldQueryData,
            data: newData.data
          };
        });
      }
    }
  });
};

export const useDeleteVideoMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (uuid: string) => RestService.delete('/videos/' + uuid),
    onSuccess: async (newData: any, uuid) => {
      const queryKey = [QUERY_KEYS.VIDEOS];
      await queryClient.cancelQueries({ queryKey });
      const oldData = queryClient.getQueryData(queryKey);
      if (!!oldData) {
        queryClient.setQueryData(queryKey, (oldQueryData: any) => {
          return {
            ...oldQueryData,
            pages: oldQueryData.pages.map((pageData: any) => {
              return {
                ...pageData,
                data: pageData.data.filter(item => item.uuid !== uuid)
              };
            })
          };
        });
      }
      const queryKey1 = [QUERY_KEYS.SAVED];
      const oldData1 = queryClient.getQueryData(queryKey1);
      if (!!oldData1) {
        queryClient.setQueryData(queryKey1, (oldQueryData: any) => {
          return {
            ...oldQueryData,
            pages: oldQueryData.pages.map((pageData: any) => {
              return {
                ...pageData,
                data: pageData.data.filter(item => item.uuid !== uuid)
              };
            })
          };
        });
      }
      const queryKey2 = [QUERY_KEYS.VIDEOS, newData.data.uuid];
      await queryClient.cancelQueries({ queryKey: queryKey2 });
      const oldData2 = queryClient.getQueryData(queryKey2);
      if (!!oldData2) {
        queryClient.removeQueries({ queryKey: queryKey2 });
      }
    }
  });
};

export const handleVideoUpdateMutation = async (queryClient, video) => {
  const currentVideo = useVideoStore.getState().video;
  if (video.uuid === currentVideo.uuid) {
    useVideoStore.setState({ video });
  }
  const queryKey = [QUERY_KEYS.VIDEOS, video.uuid];
  const oldData2 = queryClient.getQueryData(queryKey);
  if (!!oldData2) {
    queryClient.setQueryData(queryKey, (oldQueryData: any) => {
      return {
        ...oldQueryData,
        data: video
      };
    });
  }
};
