import { advertisementTags } from '@/remotion/compositions/advertisements/AdvertisementsSchema.ts';
import OfferAdvertisement from '@/remotion/compositions/advertisements/OfferAdvertisement';
import BirthdayInvitationWithFlowers from '@/remotion/compositions/birthday/BirthdayInvitationWithFlowers';
import BirthdayInvitationWithGarlands from '@/remotion/compositions/birthday/BirthdayInvitationWithGarlands';
import BirthdayInvitationWithLights from '@/remotion/compositions/birthday/BirthdayInvitationWithLights';
import BirthdayInvitationWithRotatingFlowers from '@/remotion/compositions/birthday/BirthdayInvitationWithRotatingFlowers';
import BirthdayInvitationWithVintage from '@/remotion/compositions/birthday/BirthdayInvitationWithVintage';
import { birthdayTags } from '@/remotion/compositions/birthday/BirthdaySchema';
import BirthdayWishesWithBalloons from '@/remotion/compositions/birthday/BirthdayWishesWithBalloons.tsx';
import BirthdayWishesWithFlowers from '@/remotion/compositions/birthday/BirthdayWishesWithFlowers';
import BirthdayWishesWithSprings from '@/remotion/compositions/birthday/BirthdayWishesWithSprings.tsx';
import BirthdayWishesWithVectors from '@/remotion/compositions/birthday/BirthdayWishesWithVectors.tsx';
import { festivalTags } from '@/remotion/compositions/festivals/FestivalsSchema';
import FestivalWishesForChristmas from '@/remotion/compositions/festivals/FestivalWishesForChristmas';
import FestivalWishesForDiwali from '@/remotion/compositions/festivals/FestivalWishesForDiwali';
import FestivalWishesForDiwaliWithDiyas from '@/remotion/compositions/festivals/FestivalWishesForDiwaliWithDiyas';
import FestivalWishesForHoli from '@/remotion/compositions/festivals/FestivalWishesForHoli';
import FestivalWishesForIndependenceDay from '@/remotion/compositions/festivals/FestivalWishesForIndependenceDay';
import FestivalWishesForRakshaBandan from '@/remotion/compositions/festivals/FestivalWishesForRakshaBandan';
import FestivalWishesForRamadan from '@/remotion/compositions/festivals/FestivalWishesForRamadan';
import FestivalWishesForSnowman from '@/remotion/compositions/festivals/FestivalWishesForSnowman';
import HouseWarmingInvitationWithFlowers from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithFlowers.tsx';
import HouseWarmingInvitationWithGarlands from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithGarlands.tsx';
import HouseWarmingInvitationWithLights from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithLights.tsx';
import HouseWarmingInvitationWithRotatingFlowers from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithRotatingFlowers.tsx';
import HouseWarmingInvitationWithVintage from '@/remotion/compositions/house-warming/HouseWarmingInvitationWithVintage.tsx';
import { houseWarmingTags } from '@/remotion/compositions/house-warming/HouseWarmingSchema';
import AnniversaryWishesWithBalloons from '@/remotion/compositions/life-events/AnniversaryWishesWithBalloons.tsx';
import AnniversaryWishesWithFlowers from '@/remotion/compositions/life-events/AnniversaryWishesWithFlowers.tsx';
import AnniversaryWishesWithSprings from '@/remotion/compositions/life-events/AnniversaryWishesWithSprings.tsx';
import AnniversaryWishesWithVectors from '@/remotion/compositions/life-events/AnniversaryWishesWithVectors.tsx';
import { lifeEventsTags } from '@/remotion/compositions/life-events/LifeEventsSchema.ts';
import EngagementInvitationWithFlowers from '@/remotion/compositions/marriage/EngagementInvitationWithFlowers.tsx';
import EngagementInvitationWithGarlands from '@/remotion/compositions/marriage/EngagementInvitationWithGarlands.tsx';
import EngagementInvitationWithLights from '@/remotion/compositions/marriage/EngagementInvitationWithLights.tsx';
import EngagementInvitationWithRotatingFlowers from '@/remotion/compositions/marriage/EngagementInvitationWithRotatingFlowers.tsx';
import EngagementInvitationWithVintage from '@/remotion/compositions/marriage/EngagementInvitationWithVintage.tsx';
import MarriageInvitationWithFlowers from '@/remotion/compositions/marriage/MarriageInvitationWithFlowers';
import MarriageInvitationWithGarlands from '@/remotion/compositions/marriage/MarriageInvitationWithGarlands';
import MarriageInvitationWithLights from '@/remotion/compositions/marriage/MarriageInvitationWithLights';
import MarriageInvitationWithRotatingFlowers from '@/remotion/compositions/marriage/MarriageInvitationWithRotatingFlowers';
import MarriageInvitationWithVintage from '@/remotion/compositions/marriage/MarriageInvitationWithVintage';
import { marriageTags } from '@/remotion/compositions/marriage/MarriageSchema';
import LogoFadeInAnimation from '@/remotion/compositions/technology/LogoFadeInAnimation';
import LogoHeartBeatAnimation from '@/remotion/compositions/technology/LogoHeartBeatAnimation';
import LogoRotationAnimation from '@/remotion/compositions/technology/LogoRotationAnimation';
import LogoSpringAnimation from '@/remotion/compositions/technology/LogoSpringAnimation';
import SocialMediaCountAnimation from '@/remotion/compositions/technology/SocialMediaCountAnimation.tsx';
import { technologyTags } from '@/remotion/compositions/technology/TechnologySchema';
import {
  advertisementVideos,
  birthdayVideos,
  festivalVideos,
  houseWarmingVideos,
  kidsVideos,
  lifeEventsVideos,
  marriageVideos,
  technologyVideos,
  templateSkus
} from '@/ui/services/TemplateVideosService';
import { VIDEO_TYPES } from '../shared/config/constants';

export const getTemplate = (type: string) => {
  return templates.find(t => t.sku === type);
};

export const getTemplateByTag = (tag: string) => {
  return templates.find(t => t.tags.find(tg => tg.sku === tag));
};

export const getTemplateVideo = (type, videoSku: any) => {
  const template = templates.find(t => t.sku === type);
  const video = template?.videos?.find((v: any) => v.sku === videoSku);
  if (!template || !video) return null;
  return { ...video, template: { name: template.name, sku: template.sku, tags: template.tags } };
};

export const compositionMapping = {
  // Birthday
  [`${templateSkus.birthdayBalloonsWishes}-${VIDEO_TYPES.WEB}`]: BirthdayWishesWithBalloons,
  [`${templateSkus.birthdayFlowersWishes}-${VIDEO_TYPES.WEB}`]: BirthdayWishesWithFlowers,
  [`${templateSkus.birthdayVectorsWishes}-${VIDEO_TYPES.WEB}`]: BirthdayWishesWithVectors,
  [`${templateSkus.birthdaySpringsWishes}-${VIDEO_TYPES.WEB}`]: BirthdayWishesWithSprings,
  [`${templateSkus.birthdayFlowersInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithFlowers,
  [`${templateSkus.birthdayVintageInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithVintage,
  [`${templateSkus.birthdayLightsInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithLights,
  [`${templateSkus.birthdayRotatingFlowersInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithRotatingFlowers,
  [`${templateSkus.birthdayGarlandsInvitation}-${VIDEO_TYPES.WEB}`]: BirthdayInvitationWithGarlands,
  // Marriage
  [`${templateSkus.marriageFlowersInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithFlowers,
  [`${templateSkus.marriageVintageInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithVintage,
  [`${templateSkus.marriageLightsInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithLights,
  [`${templateSkus.marriageRotatingFlowersInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithRotatingFlowers,
  [`${templateSkus.marriageGarlandsInvitation}-${VIDEO_TYPES.WEB}`]: MarriageInvitationWithGarlands,
  [`${templateSkus.engagementFlowersInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithFlowers,
  [`${templateSkus.engagementVintageInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithVintage,
  [`${templateSkus.engagementLightsInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithLights,
  [`${templateSkus.engagementRotatingFlowersInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithRotatingFlowers,
  [`${templateSkus.engagementGarlandsInvitation}-${VIDEO_TYPES.WEB}`]: EngagementInvitationWithGarlands,
  // House Warming
  [`${templateSkus.houseWarmingFlowersInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithFlowers,
  [`${templateSkus.houseWarmingVintageInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithVintage,
  [`${templateSkus.houseWarmingLightsInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithLights,
  [`${templateSkus.houseWarmingRotatingFlowersInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithRotatingFlowers,
  [`${templateSkus.houseWarmingGarlandsInvitation}-${VIDEO_TYPES.WEB}`]: HouseWarmingInvitationWithGarlands,
  // Festivals
  [`${templateSkus.festivalRakshaBandanWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForRakshaBandan,
  [`${templateSkus.festivalRamadanWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForRamadan,
  [`${templateSkus.festivalSnowmanWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForSnowman,
  [`${templateSkus.festivalHoliWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForHoli,
  [`${templateSkus.festivalIndependenceDayWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForIndependenceDay,
  [`${templateSkus.festivalDiwaliWithDiyasWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForDiwaliWithDiyas,
  [`${templateSkus.festivalChristmasWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForChristmas,
  [`${templateSkus.festivalDiwaliWishes}-${VIDEO_TYPES.WEB}`]: FestivalWishesForDiwali,
  // Life Events
  [`${templateSkus.anniversaryBalloonsWishes}-${VIDEO_TYPES.WEB}`]: AnniversaryWishesWithBalloons,
  [`${templateSkus.anniversaryFlowersWishes}-${VIDEO_TYPES.WEB}`]: AnniversaryWishesWithFlowers,
  [`${templateSkus.anniversaryVectorsWishes}-${VIDEO_TYPES.WEB}`]: AnniversaryWishesWithVectors,
  [`${templateSkus.anniversarySpringsWishes}-${VIDEO_TYPES.WEB}`]: AnniversaryWishesWithSprings,
  // Technology
  [`${templateSkus.logoRotationAnimation}-${VIDEO_TYPES.WEB}`]: LogoRotationAnimation,
  [`${templateSkus.logoRotationAnimation}-${VIDEO_TYPES.MOBILE}`]: LogoRotationAnimation,
  [`${templateSkus.logoHeartBeatAnimation}-${VIDEO_TYPES.WEB}`]: LogoHeartBeatAnimation,
  [`${templateSkus.logoHeartBeatAnimation}-${VIDEO_TYPES.MOBILE}`]: LogoHeartBeatAnimation,
  [`${templateSkus.logoFadeInAnimation}-${VIDEO_TYPES.WEB}`]: LogoFadeInAnimation,
  [`${templateSkus.logoFadeInAnimation}-${VIDEO_TYPES.MOBILE}`]: LogoFadeInAnimation,
  [`${templateSkus.logoSpringAnimation}-${VIDEO_TYPES.WEB}`]: LogoSpringAnimation,
  [`${templateSkus.logoSpringAnimation}-${VIDEO_TYPES.MOBILE}`]: LogoSpringAnimation,
  [`${templateSkus.socialMediaCountAnimation}-${VIDEO_TYPES.WEB}`]: SocialMediaCountAnimation,
  // Advertisements
  [`${templateSkus.offerAdvertisement}-${VIDEO_TYPES.WEB}`]: OfferAdvertisement
};

export const templates = [
  {
    name: 'Birthday',
    sku: 'birthday',
    details:
      "Birthday wishes and invitations video templates offer a fun and creative way to celebrate special occasions. These templates come pre-designed with vibrant animations, music, and custom text options, allowing users to personalize heartfelt messages and event details. Perfect for sending digital birthday greetings or inviting guests to parties, they save time while delivering visually stunning and memorable content. Whether for kids' birthdays, milestone celebrations, or surprise parties, video templates add a unique touch to the festivities with ease and style.",
    tags: birthdayTags,
    videos: birthdayVideos
  },
  {
    name: 'Marriage',
    sku: 'marriage',
    details:
      'Marriage and engagement invitation video templates provide a modern and elegant way to announce your special day. These templates are pre-designed with beautiful animations, romantic themes, and customizable options for adding personal details like names, dates, and venue information. Ideal for sharing online, these videos offer a captivating and memorable alternative to traditional invitations, perfect for setting the tone of your wedding or engagement event. Whether formal or casual, these templates help you create stunning, personalized invitations effortlessly.',
    tags: marriageTags,
    videos: marriageVideos
  },
  {
    name: 'House Warming',
    sku: 'house-warming',
    details:
      'Housewarming invitation video templates offer a stylish and convenient way to invite friends and family to celebrate your new home. With customizable designs featuring warm themes, cozy animations, and editable text, these templates make it easy to personalize details like the date, time, and address. Perfect for sharing digitally, these videos add a creative and welcoming touch to your housewarming celebration, helping you set the right mood for this exciting milestone.',
    tags: houseWarmingTags,
    videos: houseWarmingVideos
  },
  {
    name: 'Festivals',
    sku: 'festivals',
    details:
      'Festival wishes video templates are a vibrant and festive way to send greetings during special occasions like Diwali, Christmas, Eid, and more. These templates feature lively animations, colorful themes, and customizable options for personal messages, making it easy to create and share joyful greetings. Perfect for spreading holiday cheer, they add a unique, visually stunning touch to your wishes, ensuring your greetings stand out and bring smiles to loved ones.',
    tags: festivalTags,
    videos: festivalVideos
  },
  {
    name: 'Kids',
    sku: 'kids',
    details:
      "Kids' function video templates, such as for birthdays, dhoti functions, and half saree ceremonies, provide a playful and vibrant way to celebrate special milestones. These templates feature charming animations, colorful designs, and customizable text, allowing you to personalize event details and messages. Perfect for creating digital invitations or video highlights, they capture the joy and tradition of these memorable moments with ease and creativity. Ideal for sharing with friends and family, these templates add a festive and heartwarming touch to your child’s special day.",
    tags: [...birthdayTags],
    videos: kidsVideos
  },
  {
    name: 'Life Events',
    sku: 'life-events',
    details:
      "Life event video templates, such as for anniversaries, retirements, and memorials, offer a heartfelt way to celebrate and honor significant milestones. These templates come with elegant designs, soothing animations, and customizable options to add personal details like messages, names, and dates. Perfect for creating memorable digital tributes, invitations, or celebration videos, they help capture the emotions of these special moments, whether commemorating years of togetherness, a career's conclusion, or paying tribute to a loved one’s memory.",
    tags: lifeEventsTags,
    videos: lifeEventsVideos
  },
  {
    name: 'Technology',
    sku: 'technology',
    details:
      'Animating logos, YouTube follower achievements, and Twitter followers video templates provide an engaging way to highlight milestones and boost brand visibility. These templates feature dynamic animations and sleek designs, perfect for showcasing growing follower counts or celebrating social media achievements. With customizable options, you can personalize your brand’s logo or follower milestones, making them ideal for social media shoutouts, promotional videos, or celebratory posts. These templates add a professional and captivating touch to your content, enhancing engagement and recognition across platforms.',
    tags: technologyTags,
    videos: technologyVideos
  },
  {
    name: 'Advertisements',
    sku: 'advertisements',
    details:
      'Animating advertisements for social media and product promotion video templates offer a dynamic and eye-catching way to market your brand. These templates feature bold animations, engaging visuals, and customizable text, making it easy to showcase your products, services, or special offers. Ideal for platforms like Instagram, Facebook, and YouTube, they help capture attention quickly and convey your message effectively. With professional designs and seamless transitions, these templates are perfect for creating promotional ads that boost visibility and drive engagement with your target audience.',
    tags: advertisementTags,
    videos: advertisementVideos
  }
];
