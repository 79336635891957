import { Easing, interpolate, spring } from 'remotion';

export const fadeInEffect = (frame, from, to) =>
  interpolate(frame, [from, to], [0, 1], {
    extrapolateLeft: 'clamp',
    extrapolateRight: 'clamp'
  });

export const springEffect = (frame, fps, from, to, duration) => {
  return spring({ fps, frame, from, to, durationInFrames: duration });
};

export const rotateEffect = (frame, from, to, fromRotate, toRotate) =>
  interpolate(frame, [from, to], [fromRotate, toRotate], {
    extrapolateLeft: 'clamp',
    extrapolateRight: 'clamp'
  });

export const heartBeatEffect = (frame, totalFrames) =>
  interpolate(frame, [0, totalFrames * 0.25, totalFrames * 0.5, totalFrames * 0.75, totalFrames], [0.8, 1.2, 0.8, 1.2, 0.8], {
    extrapolateLeft: 'clamp',
    extrapolateRight: 'clamp',
    easing: Easing.bezier(0.5, 0, 0.5, 1)
  });
