import Payment from './Payment';

interface Props {
  videoUuid: string;
}

const PaymentBanner = (props: Props) => {
  return (
    <div className="flex justify-between items-center border border-border rounded-lg p-2 mb-3 bg-background">
      <div className="pr-2 leading-relaxed">Pay ₹199 to get Watermark free HD video & schedule wishes to your friends & family.</div>
      <Payment title="Pay ₹199" amount="19900" videoUuid={props.videoUuid} />
    </div>
  );
};

export default PaymentBanner;
